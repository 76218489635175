define("bottomline-execution/components/end-inventory", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    model: null,
    state: null,
    tripStopStorage: null,
    showMaterials: true,
    showTruckDetails: true,
    showTrailerDetails: true,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('showMaterials', !this.get('state.truckSettings.compartments'));
    }
  });

  _exports.default = _default;
});