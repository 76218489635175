define("bottomline-execution/templates/components/standalone-option-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pRT28195",
    "block": "[[[10,0],[14,0,\"standalone-option-switch item-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"        \"],[10,1],[12],[1,[28,[35,2],null,[[\"key\"],[[33,3]]]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,\"label\"],[14,0,\"label-switch\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"type\",\"checked\"],[\"checkbox\",[33,5]]]]],[1,\"\\n        \"],[10,0],[14,0,\"checkbox\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"showLabel\",\"editable-t\",\"label\",\"input\",\"value\"]]",
    "moduleName": "bottomline-execution/templates/components/standalone-option-switch.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});