define("bottomline-execution/components/app-events", ["exports", "@ember/application", "@ember/component", "jquery", "ember-cli-framework7/mixins/application-view"], function (_exports, _application, _component, _jquery, _applicationView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_applicationView.default, {
    navigate: "",
    didInsertElement: function didInsertElement() {
      this._super();

      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
      (0, _jquery.default)(document).delegate('button', 'a', 'click', function (ev) {
        var $el = (0, _jquery.default)(ev.currentTarget);
        $el.prop('disabled', true);
        $el.addClass('disabled-link');
        $el.parent().addClass('disabled');
        setTimeout(function () {
          $el.prop('disabled', false);
          $el.removeClass('disabled-link');
          $el.parent().removedClass('disabled');
        }, 10000);
      });

      if (document.body.offsetWidth < 800) {
        (0, _jquery.default)('html').css('zoom', '0.7');
      }
    },
    onKeyboardDidShow: function onKeyboardDidShow() {
      (0, _jquery.default)('.navbar').hide();
    },
    onKeyboardDidHide: function onKeyboardDidHide() {
      (0, _jquery.default)('.navbar').show();
    },
    onDeviceReady: function onDeviceReady() {
      var _this = this;

      document.addEventListener('native.keyboardhide', this.onKeyboardDidHide);
      document.addEventListener('native.keyboardshow', this.onKeyboardDidShow);
      document.addEventListener('backbutton', function (e) {
        var application = (0, _application.getOwner)(_this).lookup('controller:application');
        var currentPath = application.currentPath;
        e.preventDefault();

        _this.get('navigate')(currentPath);
      }, false);
    }
  });

  _exports.default = _default;
});