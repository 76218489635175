define("bottomline-execution/templates/pictures/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FwPgjIZc",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,\"img\"],[15,\"src\",[30,1,[\"url\"]]],[14,\"width\",\"200px\"],[12],[13],[1,\"\\n\"]],[1]],null]],[\"picture\"],false,[\"each\",\"-track-array\",\"model\"]]",
    "moduleName": "bottomline-execution/templates/pictures/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});