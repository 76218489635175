define("bottomline-execution/routes/edit-truck", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    toast: (0, _service.inject)(),
    model: function model() {
      var _this = this;

      return this.store.findAll('truck', {
        reload: true
      }).catch(function (error) {
        if (error == 'Unauthorized') {
          _this.transitionTo('dashboard');

          _this.get('toast').error("Unauthorized");
        }
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      if (model.get('length') === 1) {
        var currentTruck = model.get('firstObject');
        controller.send('select', currentTruck);
      }
    }
  });

  _exports.default = _default;
});