define("bottomline-execution/mirage/factories/question", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    name: function name(n) {
      return "".concat(n, " question");
    }
  });

  _exports.default = _default;
});