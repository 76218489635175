define("bottomline-execution/routes/settings", ["exports", "@ember/routing/route", "bottomline-execution/utils/crypto-utils"], function (_exports, _route, _cryptoUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model: function model() {
      return this.get('store').find('state', 1);
    },
    setupController: function setupController(_controller, _model) {
      this._signIn();

      this._super.apply(this, arguments);
    },
    _signIn: function _signIn() {
      var _this = this;

      if (this._adminSignedIn()) return;
      this.get('f7').modalPassword('Only for admins', 'Pincode', function (password) {
        if (_cryptoUtils.default.checkEqualityWithVerificationHash(password)) {
          _this.set('userInputAdminPass', password);

          return;
        }

        _this._gotoMainWindow();
      }, function () {
        _this._gotoMainWindow();
      });
    },
    _adminSignedIn: function _adminSignedIn() {
      return _cryptoUtils.default.checkEqualityWithVerificationHash(this.get('userInputAdminPass'));
    },
    _gotoMainWindow: function _gotoMainWindow() {
      document.location = window.cordova ? 'index.html' : '/';
    }
  });

  _exports.default = _default;
});