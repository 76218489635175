define("bottomline-execution/templates/components/odometer-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6tRFx8DR",
    "block": "[[[10,0],[14,0,\"item-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"key\"],[[33,1]]]]],[1,\"\\n\"],[41,[33,3],[[[1,\"        \"],[11,3],[24,6,\"javascript:void(0);\"],[24,0,\"button button-fill get-mileage\"],[4,[38,4],[[30,0],\"getMileage\"],null],[12],[1,\"Get mileage\"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"numericValue\",\"hasError\"],[[33,6],[33,7]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\",\"label\",\"if\",\"isAtx\",\"action\",\"input-number\",\"odometerValue\",\"hasError\"]]",
    "moduleName": "bottomline-execution/templates/components/odometer-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});