define("bottomline-execution/templates/components/authorize-form/pre-auth/tank-start-inventory-qty", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YuDbeURa",
    "block": "[[[41,[33,1],[[[1,\"  \"],[1,[34,2]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"item-input\"],[12],[1,[28,[35,3],null,[[\"numericValue\"],[[33,2]]]]],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"editableUllage\",\"startInventoryQty\",\"input-number\"]]",
    "moduleName": "bottomline-execution/templates/components/authorize-form/pre-auth/tank-start-inventory-qty.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});