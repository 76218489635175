define("bottomline-execution/services/location", ["exports", "@ember/object/computed", "@ember/utils", "@ember/service", "@ember/runloop", "bottomline-execution/config/environment", "bottomline-execution/utils/guid-util"], function (_exports, _computed, _utils, _service, _runloop, _environment, _guidUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    currentLocation: null,
    lat: null,
    lng: null,
    accuracy: null,
    altitudeAccuracy: null,
    ownerCode: null,
    truckId: null,
    trackingInProgress: false,
    init: function init() {
      this._super.apply(this, arguments);

      this._initializeTracking();
    },
    _initializeTracking: function _initializeTracking() {
      var backgroundModeOn = typeof cordova !== 'undefined' && (0, _utils.isPresent)(cordova.plugins) && (0, _utils.isPresent)(cordova.plugins.backgroundMode);

      if (backgroundModeOn) {
        cordova.plugins.backgroundMode.setDefaults({
          title: 'Bottomline Execution',
          text: 'smarter ways',
          icon: "icon"
        });
        document.addEventListener('deviceready', _runloop.run.bind(this, this.startTracking), false);
      } else {
        this.startTracking();
      }
    },
    onResume: function onResume() {
      cordova.plugins.backgroundMode.disable();
    },
    onPause: function onPause() {
      setTimeout(function () {
        if ((0, _utils.isPresent)(cordova)) {
          cordova.plugins.backgroundMode.enable();
        }
      }, 5000);
    },
    startTracking: function startTracking() {
      document.addEventListener('pause', _runloop.run.bind(this, this.onPause), false);
      document.addEventListener('resume', _runloop.run.bind(this, this.onResume), false);

      if (!this.get('trackingInProgress')) {
        if ((0, _utils.isPresent)(window.cordova) && (0, _utils.isPresent)(window.plugin) && _typeof(window.plugin.locationServices) === "object" && _environment.default.APP.target !== 'atx') {
          window.plugin.locationServices.geolocation.getCurrentPosition(_runloop.run.bind(this, this.geolocationSuccess), _runloop.run.bind(this, this.geolocationError), this.geolocationConfig);
        } else {
          navigator.geolocation.getCurrentPosition(_runloop.run.bind(this, this.geolocationSuccess), _runloop.run.bind(this, this.geolocationError), this.geolocationConfig);
        }

        this.set('trackingInProgress', true);
      }
    },
    geolocationSuccess: function geolocationSuccess(location) {
      var currentTruck = this.get('appState').getTruck();
      if ((0, _utils.isEmpty)(currentTruck)) return;
      var truckPosition = this.get('store').createRecord('truck-position', {
        id: (0, _guidUtil.guidUtil)(),
        ownerCode: this.get('ownerCode'),
        truckId: this.get('truckId'),
        haulierId: currentTruck.get('HaulierID'),
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        speed: location.coords.speed,
        changeDt: new Date(location.timestamp)
      });
      truckPosition.saveAndUnload();
      this.set('currentLocation', truckPosition);
      this.set('lng', location.coords.longitude);
      this.set('lat', location.coords.latitude);
      setTimeout(_runloop.run.bind(this, function () {
        this.set('trackingInProgress', false);
        this.startTracking();
      }), 60000);
    },
    geolocationError: function geolocationError() {
      this.resetWithDefaultValues();
      setTimeout(_runloop.run.bind(this, function () {
        this.set('trackingInProgress', false);
        this.startTracking();
      }), 60000);
    },
    geolocationConfig: {
      enableHighAccuracy: true,
      timeout: 50000,
      maximumAge: 0
    },
    resetWithDefaultValues: function resetWithDefaultValues() {
      this.setProperties({
        currentLocation: null,
        lng: null,
        lat: null
      });
    }
  });

  _exports.default = _default;
});