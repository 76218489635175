define("bottomline-execution/templates/components/trip-stop-remarks", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "i7r0YDAG",
    "block": "[[[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"      \"],[10,\"tr\"],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,0,\"col-100\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"remark\"],false,[\"each\",\"-track-array\",\"remarks\"]]",
    "moduleName": "bottomline-execution/templates/components/trip-stop-remarks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});