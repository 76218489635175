define("bottomline-execution/routes/trip-stops/show/index", ["exports", "@ember/utils", "@ember/routing/route", "bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages", "bottomline-execution/mixins/trip-stop-type"], function (_exports, _utils, _route, _plannedOrConfirmedTripStopStorages, _tripStopType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_plannedOrConfirmedTripStopStorages.default, _tripStopType.default, {
    model: function model(_, transition) {
      var tripId = transition.routeInfos.findBy('name', 'trips.show').params['trip_id'];
      var tripStopId = transition.routeInfos.findBy('name', 'trip-stops.show').params['trip_stop_id'];
      var recordType = this.tripStopType(tripId, tripStopId);
      return this.get('store').peekRecord(recordType, tripStopId);
    },
    setupController: function setupController(controller, model) {
      if (model.get('isLoad')) {
        model.get('trip_stop_storages').forEach(function (tripStopStorage) {
          tripStopStorage.calculateTotalCompartmentQty();
        });
      } else {
        controller.resetUnloadCompartmentFilters();

        var unloadTripStopStorage = this._tripStopStorages(model).sortBy('storage.SequenceNb').get('firstObject');

        controller.set('unloadTripStopStorage', unloadTripStopStorage);
      }

      this._super(controller, model);
    },
    _tripStopStorages: function _tripStopStorages(tripStop) {
      var plannedOrConfirmedTripStopStorages = this.plannedOrConfirmedTripStopStorages({
        tripStop: tripStop,
        tripStopStorages: tripStop.get('trip_stop_storages'),
        storageGroupID: tripStop.get('StorageGroupID'),
        includeUnplannedStorages: false
      });

      if ((0, _utils.isPresent)(plannedOrConfirmedTripStopStorages)) {
        return plannedOrConfirmedTripStopStorages;
      } else {
        return tripStop.get('trip_stop_storages');
      }
    }
  });

  _exports.default = _default;
});