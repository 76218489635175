define("bottomline-execution/models/storage-material", ["exports", "@ember/object", "ember-data"], function (_exports, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    StorageID: attr('number'),
    MaterialID: attr('string'),
    SiteID: attr('string'),
    ToDate: attr('date'),
    storage: belongsTo('storage'),
    material: (0, _object.computed)('MaterialID', 'isLoaded', function () {
      if (this.get('MaterialID')) {
        return this.store.peekRecord('material', this.get('MaterialID'));
      }
    })
  });

  _exports.default = _default;
});