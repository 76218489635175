define("bottomline-execution/templates/trip-stops/show/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0KfjKIqI",
    "block": "[[[41,[33,1,[\"isEndInventory\"]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"model\",\"state\",\"tripStopStorage\",\"isAtx\",\"offline\",\"showTruckCompartments\",\"showTrailerCompartments\",\"truckIsEmpty\",\"confirm\",\"capture\",\"cancel\",\"viewGallery\"],[[33,1],[33,3],[33,4],[33,5],[33,6],[33,7],[33,8],[28,[37,9],[[30,0],\"truckIsEmpty\"],null],[28,[37,9],[[30,0],\"confirm\"],null],[28,[37,9],[[30,0],\"capture\"],null],[28,[37,9],[[30,0],\"cancel\"],null],[28,[37,9],[[30,0],\"viewGallery\"],null]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,1,[\"isLoad\"]],[[[1,\"  \"],[19,\"trip-stops/show/load\",[]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,1,[\"isUnload\"]],[[[1,\"  \"],[19,\"trip-stops/show/unload\",[]],[1,\"\\n\"]],[]],null]],[],true,[\"if\",\"model\",\"end-inventory\",\"state\",\"loadTripStopStorage\",\"isAtx\",\"offline\",\"showTruckCompartments\",\"showTrailerCompartments\",\"action\",\"partial\"]]",
    "moduleName": "bottomline-execution/templates/trip-stops/show/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});