define("bottomline-execution/models/bx/trip-stop-storage", ["exports", "bottomline-execution/models/trip-stop-storage", "@ember/object", "bottomline-execution/mixins/trip-stop-type"], function (_exports, _tripStopStorage, _object, _tripStopType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _tripStopStorage.default.extend(_tripStopType.default, {
    isVolatileRemoveableRecord: (0, _object.computed)('isLocalRecord', function () {
      if (this.get('trip_stop.isStartInventory')) {
        return false;
      } else {
        return this._super.apply(this, arguments);
      }
    }),
    trip_stop: (0, _object.computed)('TripStopID', function () {
      if (this.get('TripStopID')) {
        return this.store.peekRecord(this.tripStopType(), this.get('TripStopID'));
      }
    })
  });

  _exports.default = _default;
});