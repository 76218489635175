define("bottomline-execution/services/request-headers", ["exports", "rsvp", "@ember/service", "bottomline-execution/mixins/version"], function (_exports, _rsvp, _service, _version) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend(_version.default, {
    deviceInformation: (0, _service.inject)(),
    session: (0, _service.inject)(),
    headers: function headers() {
      var _this = this;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$authorize = _ref.authorize,
          authorize = _ref$authorize === void 0 ? false : _ref$authorize;

      return new _rsvp.Promise(function (resolve) {
        var headers = _this.defaultHeaders({
          authorize: authorize
        });

        _this.get('deviceInformation').getMacAddress().then(function (macaddress) {
          headers['device-guid'] = macaddress;
          resolve(headers);
        }, function (_failure) {
          resolve(headers); // Do not set 'device-guid' if there is no MAC address
        });
      });
    },
    defaultHeaders: function defaultHeaders() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref2$authorize = _ref2.authorize,
          authorize = _ref2$authorize === void 0 ? false : _ref2$authorize;

      var session = this.get('session');
      var headers = {
        'App-Version': this.get('appVersionNumber'),
        'Content-Type': 'application/json'
      };

      if (authorize) {
        var authorizerName = session.get('session.authenticator').split(':')[1];
        session.authorize("authorizer:".concat(authorizerName), function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
      }

      return headers;
    }
  });

  _exports.default = _default;
});