define("bottomline-execution/components/details-end-inventory", ["exports", "@ember/object/computed", "@ember/component", "@ember/service"], function (_exports, _computed, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: 'storage-compartments',
    tagName: 'div',
    appConfiguration: (0, _service.inject)(),
    truckSettings: (0, _computed.alias)('appConfiguration.truckSettings'),
    tripStopStorage: null
  });

  _exports.default = _default;
});