define("bottomline-execution/templates/components/add-language", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UuUtPkfP",
    "block": "[[[10,0],[14,0,\"card list-block\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"key\"],[\"add_language\"]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-content-inner\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"list-block\"],[12],[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n          \"],[1,[28,[35,1],null,[[\"value\",\"label\"],[[33,2],\"language\"]]]],[1,\"\\n          \"],[1,[28,[35,1],null,[[\"value\",\"label\"],[[33,3],\"description\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"card-footer\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onClick\",[28,[37,4],[[30,0],\"add\"],null]],[14,0,\"button\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"add\"]]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\",\"f7-text-field\",\"value\",\"description\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/add-language.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});