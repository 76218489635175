define("bottomline-execution/services/application-reload", ["exports", "@ember/service", "@ember/runloop"], function (_exports, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    _reloadedAt: function _reloadedAt() {
      var reloadedAt = localStorage.getItem('reloadedAt');
      return reloadedAt ? Number(reloadedAt) : null;
    },
    _maybeReload: function _maybeReload() {
      if (this._isAppStale()) {
        window.location.reload();
      }
    },
    _isAppStale: function _isAppStale() {
      var appReloadInterval = 60 * 60 * 12; // 12 hours in seconds

      var currentTime = moment().unix();

      if (this._reloadedAt() && moment(this._reloadedAt() + appReloadInterval).isBefore(currentTime)) {
        this._setReloadedAt({
          currentTime: currentTime
        });

        return true;
      }

      {
        return false;
      }
    },
    _setReloadedAt: function _setReloadedAt() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
        currentTime: moment().unix()
      },
          _ref$currentTime = _ref.currentTime,
          currentTime = _ref$currentTime === void 0 ? moment().unix() : _ref$currentTime;

      localStorage.setItem('reloadedAt', currentTime);
    },
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (!window.cordova) {
        this._setReloadedAt();

        _runloop.run.later(function () {
          // check every 10 minutes
          setInterval(function () {
            return _this._maybeReload();
          }, 10 * 60 * 1000);
        });
      }
    }
  });

  _exports.default = _default;
});