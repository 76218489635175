define("bottomline-execution/adapters/bx/pdf-document", ["exports", "bottomline-execution/adapters/bx/base-adapter"], function (_exports, _baseAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseAdapter.default.extend({
    _baseUrl: function _baseUrl(shiftId, tripId) {
      var truckId = this.get('compoundVehicleId');
      var endpointBase = "execution/trucks/".concat(truckId, "/shifts/").concat(shiftId, "/trips/").concat(tripId);
      return "".concat(this.get('ajaxService.host')).concat(this.get('truckService'), "/").concat(endpointBase);
    },
    urlForDownloadRecord: function urlForDownloadRecord(snapshot) {
      var modelId = snapshot.attr('modelId');

      switch (snapshot.attr('documentType')) {
        case 'tripReport':
          return this._urlForTripReport(modelId);

        case 'tripStopReport':
          return this._urlForTripStopReport(modelId);
      }
    },
    _urlForTripReport: function _urlForTripReport(tripId) {
      var trip = this.get('store').peekRecord('trip', tripId);
      var shiftId = trip.get('shift.id');
      return "".concat(this._baseUrl(shiftId, tripId), "/delivery-tickets");
    },
    _urlForTripStopReport: function _urlForTripStopReport(tripStopId) {
      var tripStop = this.get('store').peekRecord('trip-stop', tripStopId);
      var tripId = tripStop.get('trip.id');
      var shiftId = tripStop.get('trip.shift.id');
      return "".concat(this._baseUrl(shiftId, tripId), "/stops/").concat(tripStopId, "/delivery-ticket");
    },
    query: function query(store, type, _query) {
      var documentType = _query.documentType,
          modelId = _query.modelId;
      return {
        documents: [{
          modelId: modelId,
          documentType: documentType
        }]
      };
    }
  });

  _exports.default = _default;
});