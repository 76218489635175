define("bottomline-execution/components/input-number-text", ["exports", "@ember/component/text-field", "@ember/object", "@ember/utils"], function (_exports, _textField, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textField.default.extend({
    tagName: 'input',
    type: 'number',
    numericValue: (0, _object.computed)('value', {
      get: function get() {
        var val = parseFloat(this.get('value'));

        if ((0, _utils.isEmpty)(val) || isNaN(val)) {
          return undefined;
        } else {
          return val;
        }
      },
      set: function set(key, value) {
        this.set('value', (0, _utils.isEmpty)(value) ? undefined : value);
      }
    })
  });

  _exports.default = _default;
});