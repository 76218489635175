define("bottomline-execution/templates/trip-stops/show/authorize-delivery", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "si5Uw9Ny",
    "block": "[[[3,\" Views\"],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[6,[39,1],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"center\"],[12],[1,\"\\n    \"],[1,[28,[35,2],null,[[\"key\"],[\"trips-stops.authorize_delivery\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[10,0],[14,0,\"pages\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"page\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n      \"],[1,[28,[35,3],null,[[\"trip-stop\",\"postProcess\",\"toNextState\",\"cancel\"],[[33,4],true,[28,[37,5],[[30,0],\"toTrip\"],null],[28,[37,5],[[30,0],\"cancel\"],null]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"f7-views\",\"f7-inner-navbar\",\"editable-t\",\"authorize-form\",\"model\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/trip-stops/show/authorize-delivery.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});