define("bottomline-execution/templates/components/report-viewer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IJGm7fK6",
    "block": "[[[10,3],[14,6,\"javascript:void(0)\"],[14,0,\"classNames\"],[12],[18,1,null],[13],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "bottomline-execution/templates/components/report-viewer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});