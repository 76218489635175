define("bottomline-execution/components/action-button", ["exports", "@ember/component", "@ember/object/computed", "@ember/object", "@ember/application", "@ember/utils"], function (_exports, _component, _computed, _object, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: 'a',
    attributeBindings: ['href'],
    href: (0, _object.computed)('router', function () {
      var url = this.get('router').generate(this.get('target'), this.get('id'));
      return url;
    }),
    id: (0, _computed.or)('trip.id', 'shift.id'),
    classNames: ['button', 'button-fill', 'button-lg', 'v-spaced'],
    classNameBindings: ['disabled'],
    disabled: (0, _object.computed)('enabled', function () {
      return (0, _utils.isPresent)(this.get('enabled')) && !this.get('enabled');
    }),
    appInstance: (0, _object.computed)(function () {
      return (0, _application.getOwner)(this);
    }),
    router: (0, _object.computed)('appInstance', function () {
      return this.get('appInstance').lookup('router:main');
    })
  });

  _exports.default = _default;
});