define("bottomline-execution/templates/components/editable-t", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gk2IMqkV",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onClick\",[28,[37,2],[[30,0],\"toggleEdit\"],[[\"bubbles\"],[false]]]],[15,0,[29,[[52,[33,3],\"button-red\"]]]],[12],[1,\"T\"],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"      \"],[18,1,[[30,0]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,6],[[33,7]],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,6],[[33,7]],null]],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"canEdit\",\"action\",\"isMissing\",\"has-block\",\"yield\",\"t\",\"key\"]]",
    "moduleName": "bottomline-execution/templates/components/editable-t.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});