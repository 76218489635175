define("bottomline-execution/routes/trip-stops/change-terminal", ["exports", "@ember/object/computed", "@ember/service", "@ember/routing/route"], function (_exports, _computed, _service, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    model: function model() {
      var currentTruck = this.get('appState.currentTruck');
      return this.store.query('terminal', {
        filter: {
          truck_id: currentTruck
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentLoadStop', this.modelFor('trips.show').get('loadStop'));
    }
  });

  _exports.default = _default;
});