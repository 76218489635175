define("bottomline-execution/routes/trip-stops/authorize-site", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    actions: {
      cancel: function cancel() {
        this.transitionTo('trips');
      }
    }
  });

  _exports.default = _default;
});