define("bottomline-execution/helpers/formatDateTime", ["exports", "@ember/component/helper", "@ember/service", "@ember/object/computed"], function (_exports, _helper, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    appConfiguration: (0, _service.inject)(),
    dateTimeFormat: (0, _computed.alias)('appConfiguration.truckSettings.date_time_format'),
    compute: function compute(value) {
      var dateTimeFormat = this.get('dateTimeFormat').toString();
      var date = new Date(value);
      if (date.toString() === 'Invalid Date') return '';
      return moment(date).format(dateTimeFormat);
    }
  });

  _exports.default = _default;
});