define("bottomline-execution/helpers/material-load-horizontal-bar", ["exports", "@ember/component/helper", "ember"], function (_exports, _helper, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var htmlSafe = _ember.default.String.htmlSafe;

  var _default = (0, _helper.helper)(function (params, _ref) {
    var color = _ref.color,
        currentQty = _ref.currentQty,
        maxQty = _ref.maxQty;
    var targetColor = color || '#00d190';
    var targetMaxQty = Number(maxQty);

    if (currentQty) {
      return htmlSafe("background: ".concat(targetColor, ";height: 20px;") + "width: ".concat(getPercentage(currentQty, targetMaxQty), "%; position: absolute; bottom: 0px;transition: width 5s"));
    }
  });

  _exports.default = _default;

  function getPercentage(currentQty, maxQty) {
    if (maxQty) {
      return (currentQty / maxQty * 100).toFixed(2);
    }

    return '100';
  }
});