define("bottomline-execution/services/customer-configuration", ["exports", "@ember/object/computed", "rsvp", "@ember/service", "bottomline-execution/config/environment", "bottomline-execution/utils/url-utils"], function (_exports, _computed, _rsvp, _service, _environment, _urlUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    ajax: (0, _service.inject)(),
    requestHeaders: (0, _service.inject)(),
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    loadConfiguration: function loadConfiguration(configAlias) {
      var _this = this;

      return new _rsvp.Promise(function (resolve, reject) {
        _this.getTabletConfiguration(configAlias).then(function (response) {
          if (response && response.customer_domain) {
            var config_alias = response.config_alias,
                customer_domain = response.customer_domain,
                android_logging = response.android_logging,
                application_logging = response.application_logging,
                backend_type = response.backend_type,
                auth0 = response.auth0;

            var domain = _urlUtils.default.stripTrailingSlash(customer_domain);

            _this.get('appState').setProperties({
              backend_type: backend_type,
              auth0: auth0,
              domain: domain,
              alias: config_alias,
              android_logging: android_logging,
              application_logging: application_logging
            });

            _this.get('appState').save();
          } else if (configAlias) {
            reject('invalid_alias_or_domain');
          }

          resolve('ok');
        }, function (_error) {
          // Enable to continue with locally cached values
          resolve('use_cached_configuration');
        });
      });
    },
    getTabletConfiguration: function getTabletConfiguration(configAlias) {
      var _this2 = this;

      return new _rsvp.Promise(function (resolve, reject) {
        _this2.get('requestHeaders').headers().then(function (headers) {
          _this2.get('ajax').request(_environment.default.configurationEndpoint + "/tablet_configurations.json", {
            method: 'GET',
            data: {
              config_alias: configAlias
            },
            headers: headers
          }).then(function (response) {
            resolve(response);
          }, function (error) {
            reject(error);
          });
        });
      });
    },
    updateTabletConfiguration: function updateTabletConfiguration(params) {
      var _this3 = this;

      return new _rsvp.Promise(function (resolve, reject) {
        _this3.get('requestHeaders').headers().then(function (headers) {
          _this3.get('ajax').request(_environment.default.configurationEndpoint + "/tablets/" + headers['device-guid'] + "/configuration", {
            method: 'PATCH',
            data: {
              configuration: params
            },
            headers: headers
          }).then(function (response) {
            resolve(response);
          }, function (error) {
            reject(error);
          });
        });
      });
    }
  });

  _exports.default = _default;
});