define("bottomline-execution/controllers/trip-stops/show/authorize-delivery", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    actions: {
      cancel: function cancel() {
        var tripStop = this.get('model');
        this.transitionToRoute('trip-stops.show.index', tripStop.get('TripID'), tripStop.get('id'));
      },
      toTrip: function toTrip() {
        var _this = this;

        var model = this.get('model');
        model.validate().then(function () {
          model.confirm();

          if (model.get('showQuestions') && model.get('showQuestionsOnFinish')) {
            _this.transitionToRoute('questions');
          } else {
            _this.transitionToRoute('trips.index');
          }
        }).catch(function () {
          _this.get('f7').alert(_this.get('i18n').t('form_incomplete'), 'Delivery+');
        });
      }
    }
  });

  _exports.default = _default;
});