define("bottomline-execution/templates/components/details-vehicle-load", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vM+lQwhX",
    "block": "[[[10,\"table\"],[12],[1,\"\\n  \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"truckSettings\",\"truckStorage\",\"trip-stop\",\"parentController\",\"showAllCompartments\",\"tripStopStorage\"],[[33,4],[30,1],[33,5],[33,6],[33,7],[33,8]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"compartment\"],false,[\"each\",\"-track-array\",\"compartments\",\"trip-stop-truck-storage-load\",\"truckSettings\",\"trip-stop\",\"parentController\",\"showAllCompartments\",\"tripStopStorage\"]]",
    "moduleName": "bottomline-execution/templates/components/details-vehicle-load.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});