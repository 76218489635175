define("bottomline-execution/services/remote-logging", ["exports", "bottomline-execution/services/ajax", "@ember/object", "@ember/utils", "@ember/service", "rsvp", "bottomline-execution/config/environment", "bottomline-execution/mixins/raw-app-state"], function (_exports, _ajax, _object, _utils, _service, _rsvp, _environment, _rawAppState) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend(_rawAppState.default, {
    store: (0, _service.inject)(),
    session: (0, _service.inject)(),
    appState: (0, _object.computed)(function () {
      return this.get('store').peekRecord('state', 1);
    }).volatile(),
    deviceInformation: (0, _service.inject)(),
    host: _environment.default.remoteLoggingEndpoint,
    log: function log(message) {
      var _this = this;

      var level = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'INFO';
      return this.get('deviceInformation').getMacAddress().then(function (macAddress) {
        return _this._sendRequest(message, level, macAddress);
      }, function (_failure) {
        return _this._sendRequest(message, level, 'unknown');
      });
    },
    _sendRequest: function _sendRequest(logMessage, logLevel, macAddress) {
      if (this._loggingEnabled()) {
        var data = {
          app: "BE",
          level: logLevel,
          message: logMessage,
          timestamp: +new Date(),
          alias: this._appState().alias,
          authenticated: this.get('session.isAuthenticated'),
          macAddress: macAddress,
          truckId: this._appState().currentTruck,
          truckDescription: this._truckIdentifier()
        };
        console.log("".concat(logMessage, " (sent to remote logging service)"));
        return this.post('remote_logs', {
          headers: {
            'Authorization': 'Basic ' + _environment.default.remoteLoggingAuth
          },
          data: JSON.stringify({
            remote_log: data
          })
        }).catch(function (error) {
          console.log('Remote logging failed:', error.message);
        });
      } else {
        console.log(logMessage);
        return new _rsvp.default.resolve();
      }
    },
    _loggingEnabled: function _loggingEnabled() {
      return _environment.default.environment != 'test' && this._appState().application_logging;
    },
    _truckIdentifier: function _truckIdentifier() {
      if ((0, _utils.isPresent)(this.get('appState'))) {
        return (this.get('appState').getTruck() || {}).identifier;
      }
    }
  });

  _exports.default = _default;
});