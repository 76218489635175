define("bottomline-execution/templates/components/site/address-details", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EJ9VU/uZ",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"table\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[1,[33,1,[\"Name\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"name\",\"size\"],[\"domain\",\"lg\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[1,[33,1,[\"Address\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[1,[33,1,[\"ZipCode\"]]],[1,\" \"],[1,[33,1,[\"City\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[1,[28,[35,2],null,[[\"name\",\"size\"],[[33,3,[\"activityIcon\"]],\"lg\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[10,3],[15,6,[29,[\"tel:\",[33,1,[\"telephoneNumbered\"]]]]],[12],[1,\"\\n          \"],[1,[28,[35,2],null,[[\"name\",\"size\"],[\"phone\",\"lg\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[1,[28,[35,4],null,[[\"key\"],[\"column.Telephone\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n        \"],[10,3],[15,6,[29,[\"tel:\",[33,1,[\"telephoneNumbered\"]]]]],[12],[1,[33,1,[\"Telephone\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"well\"],[12],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"key\"],[\"select_trip_stop\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"site\",\"material-design-icon\",\"model\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/site/address-details.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});