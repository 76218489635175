define("bottomline-execution/authenticators/auth0", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "ember-simple-auth/authenticators/base", "bottomline-execution/mixins/session-management"], function (_exports, _computed, _object, _service, _base, _sessionManagement) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend(_sessionManagement.default, {
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    ajax: (0, _service.inject)(),

    /**
     * Configure auth0 instance
     */
    auth0: (0, _object.computed)(function () {
      var auth0Config = this.get('appState.auth0'); // eslint-disable-next-line no-undef

      return new auth0.WebAuth({
        domain: auth0Config.domain,
        clientID: auth0Config.client_id,
        redirectUri: auth0Config.callback_url,
        audience: auth0Config.audience,
        responseType: 'token id_token',
        scope: 'openid profile email'
      });
    }),
    restore: function restore(data) {
      return Promise.resolve(data);
    },
    authenticate: function authenticate(username, password) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        return _this.get('ajax').request("".concat(_this.get('appState.domain'), "/auth-client/token"), {
          method: 'POST',
          data: {
            username: username,
            password: password
          }
        }).then(function (authResult) {
          _this._extractSessionData(authResult).then(function (sessionData) {
            resolve(sessionData);
          });
        }).catch(function (error) {
          console.log('Authentication failed', error);
          reject(error);
        });
      });
    },
    _extractSessionData: function _extractSessionData(authResult) {
      var _this2 = this;

      return new Promise(function (resolve) {
        var authData = (0, _object.getProperties)(authResult, 'access_token');

        var returnSessionData = function returnSessionData(profileData, authData) {
          var sessionData = Object.assign({}, profileData, authData);
          return resolve(sessionData);
        };

        return _this2._getUserProfile(authResult.access_token).then(function (userProfile) {
          var profile = (0, _object.getProperties)(userProfile, 'name', 'nickname', 'email', 'picture');
          profile['driver_id'] = userProfile['https://eu.bottomline.execution/driver_id'];
          returnSessionData(profile, authData);
        }).catch(function (error) {
          console.log('Error retrieving user profile', error);
          returnSessionData({}, authData);
        });
      });
    },

    /**
     * Use token to get user profile
     */
    _getUserProfile: function _getUserProfile(token) {
      var _this3 = this;

      return new Promise(function (resolve, reject) {
        _this3.get('auth0').client.userInfo(token, function (err, profile) {
          if (err) return reject(err);
          return resolve(profile);
        });
      });
    }
  });

  _exports.default = _default;
});