define("bottomline-execution/templates/components/overview-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JktpNyeI",
    "block": "[[[1,\"\\n\"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n  \"],[1,[34,0]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-content-inner\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"card-footer\"],[12],[1,\"\\n  \"],[18,1,[\"footer\"]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"title\",\"yield\"]]",
    "moduleName": "bottomline-execution/templates/components/overview-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});