define("bottomline-execution/controllers/trip-stops/show/authorize-site", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    actions: {
      cancel: function cancel() {
        var tripStop = this.get('model');
        tripStop.rollbackAttributes();
        this.transitionToRoute('trips.index');
      },
      toTripStop: function toTripStop() {
        var tripStop = this.get('model');
        this.transitionToRoute('trip-stops.show.index', tripStop.get('TripID'), tripStop.get('id'));
      }
    }
  });

  _exports.default = _default;
});