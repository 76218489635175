define("bottomline-execution/mirage/factories/trip", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    confirmed_sequence_nb: function confirmed_sequence_nb(n) {
      return n;
    },
    planned_start_dt: function planned_start_dt() {
      new Date();
    },
    SequenceNb: function SequenceNb(n) {
      return n;
    },
    schedule_id: 1,
    state: 'planned',
    ExportYN: false,
    time_stamp: '1232'
  });

  _exports.default = _default;
});