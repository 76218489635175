define("bottomline-execution/models/truck-setting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    // Truck settings' default values must be chosen such that BE frontend shows the correct (default) behavior even if no truck settings are configured on the backend.
    // To keep reasoning simple, all boolean truck settings should default to false.
    // Maintain alphabetical ordering here
    add_extra_order: attr('boolean', {
      defaultValue: false
    }),
    add_extra_trip_stops: attr('boolean', {
      defaultValue: false
    }),
    add_special_activity: attr('boolean', {
      defaultValue: false
    }),
    adr_report: attr('boolean', {
      defaultValue: false
    }),
    authorize_trip_stop: attr('boolean', {
      defaultValue: false
    }),
    authorize_trip_stop_driver_signature: attr('boolean', {
      defaultValue: false
    }),
    barrel_count_enabled: attr('boolean', {
      defaultValue: false
    }),
    change_terminal: attr('boolean', {
      defaultValue: false
    }),
    compartments: attr('boolean', {
      defaultValue: false
    }),
    customer_type_to_filter_orders: attr('string', {
      defaultValue: false
    }),
    // BP-5838
    customer_type_with_flow_meter: attr('string', {
      defaultValue: false
    }),
    // BP-5838
    date_time_format: attr('string', {
      defaultValue: 'DD-MM-YYYY HH:mm'
    }),
    // EP-996: all values accepted by moment.js
    default_compartment_filter_strategy: attr('string', {
      defaultValue: 'Material ID'
    }),
    delivery_ticketnb_per_tank: attr('boolean', {
      defaultValue: false
    }),
    deprecated_source_adr_report: attr('string', {
      defaultValue: false
    }),
    // EP-796: false, 'jsreport', 'on-screen'
    deprecated_source_driver_report: attr('boolean', {
      defaultValue: false
    }),
    deprecated_source_trip_report: attr('boolean', {
      defaultValue: false
    }),
    deprecated_source_trip_stop_report: attr('boolean', {
      defaultValue: false
    }),
    derive_delivery_tank_15qty: attr('boolean', {
      defaultValue: false
    }),
    dip_unplanned_storages: attr('boolean', {
      defaultValue: false
    }),
    dischargeplan_on_unload: attr('boolean', {
      defaultValue: false
    }),
    drop_down_count_enabled: attr('boolean', {
      defaultValue: false
    }),
    hide_delivery_ticket_input: attr('boolean', {
      defaultValue: false
    }),
    hide_planned_qty_on_delivery: attr('boolean', {
      defaultValue: false
    }),
    inventory_in_percentage: attr('boolean', {
      defaultValue: false
    }),
    load_as_planned_enabled: attr('boolean', {
      defaultValue: false
    }),
    load_by_weight: attr('boolean', {
      defaultValue: false
    }),
    load_ticketnb_per_tank: attr('boolean', {
      defaultValue: false
    }),
    material_transfer: attr('boolean', {
      defaultValue: false
    }),
    meter_connection_address: attr('string', {
      defaultValue: '192.168.0.3:5000'
    }),
    meter_connection_type: attr('string', {
      defaultValue: 'wifi'
    }),
    // EP-632: 'wifi', 'bluetooth'
    meter_coupling_active: attr('boolean', {
      defaultValue: false
    }),
    odometer_on_shift_level: attr('boolean', {
      defaultValue: false
    }),
    odometer_on_trip_level: attr('boolean', {
      defaultValue: false
    }),
    on_delivery_capture: attr('boolean', {
      defaultValue: false
    }),
    on_delivery_mandatory_picture: attr('boolean', {
      defaultValue: false
    }),
    on_delivery_scan: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_capture: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_mandatory_picture: attr('boolean', {
      defaultValue: false
    }),
    on_end_inventory_scan: attr('boolean', {
      defaultValue: false
    }),
    on_load_capture: attr('boolean', {
      defaultValue: false
    }),
    on_load_mandatory_picture: attr('boolean', {
      defaultValue: false
    }),
    on_load_scan: attr('boolean', {
      defaultValue: false
    }),
    postdip_enabled: attr('string', {
      defaultValue: false
    }),
    // EP-594: false, 'optional', 'required' EP-857: 'optional_by_ullage', 'required_by_ullage'
    postdip_validation_enabled: attr('boolean', {
      defaultValue: false
    }),
    predip_enabled: attr('string', {
      defaultValue: false
    }),
    // EP-595: false, 'on_unload', 'on_pre_auth' EP-857: 'on_unload_by_ullage', 'on_pre_auth_by_ullage'
    questionaire: attr('boolean', {
      defaultValue: false
    }),
    register_site_coordinates: attr('boolean', {
      defaultValue: false
    }),
    register_tank_coordinates: attr('boolean', {
      defaultValue: false
    }),
    restrict_delivery_tickets_for_customer_type: attr('string', {
      defaultValue: false
    }),
    // BP-5838
    show_atg_information: attr('boolean', {
      defaultValue: false
    }),
    show_atg_inventory_warnings: attr('boolean', {
      defaultValue: false
    }),
    show_nesenta_link: attr('boolean', {
      defaultValue: false
    }),
    show_planned_materials: attr('boolean', {
      defaultValue: false
    }),
    show_trip_id_in_trip_overview: attr('boolean', {
      defaultValue: false
    }),
    tank_is_full_enabled: attr('boolean', {
      defaultValue: false
    }),
    temp_density_ltr15_delivery_enabled: attr('string', {
      defaultValue: false
    }),
    // EP-416: false, 'open', 'closed'
    temp_density_ltr15_load_enabled: attr('string', {
      defaultValue: false
    }),
    // EP-416: false, 'open', 'closed'
    terminal_import_enabled: attr('boolean', {
      defaultValue: false
    }),
    trip_stop_reordering_enabled: attr('string', {
      defaultValue: false
    }),
    // EP-1048: false, 'within_trip', 'within_shift'
    truck_compartments_order_desc: attr('boolean', {
      defaultValue: false
    }),
    use_access_point: attr('boolean', {
      defaultValue: false
    }),
    use_material_calculation: attr('boolean', {
      defaultValue: false
    }) // Maintain alphabetical ordering here

  });

  _exports.default = _default;
});