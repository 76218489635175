define("bottomline-execution/serializers/site", ["exports", "ember-data", "active-model-adapter", "@ember/object"], function (_exports, _emberData, _activeModelAdapter, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      storages: {
        embedded: 'always'
      },
      site_remarks: {
        embedded: 'always'
      }
    },
    serialize: function serialize(snapshot) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (options.purpose === 'forReport') {
        var requiredAttributes = ['Name', 'Address', 'LoadingID', 'ExtInvoiceDebtorID', 'City', 'Telephone'];
        return (0, _object.getProperties)(snapshot.attributes(), requiredAttributes);
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});