define("bottomline-execution/models/site", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/utils", "ember-data"], function (_exports, _computed, _object, _service, _utils, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    appConfiguration: (0, _service.inject)(),
    Address: attr('string'),
    Address2: attr('string'),
    Address3: attr('string'),
    LoadingID: attr('string'),
    Brand: attr('string'),
    City: attr('string'),
    Contact: attr('string'),
    County: attr('string'),
    CountryCode: attr('string'),
    Email: attr('string'),
    ExtInvoiceDebtorID: attr('string'),
    ExtSiteID: attr('string'),
    Fax: attr('string'),
    latitude: attr('string'),
    longitude: attr('string'),
    Name: attr('string'),
    Name2: attr('string'),
    Name3: attr('string'),
    Owner: attr('string'),
    SiteType: attr('string'),
    SmsTelephone: attr('string'),
    Telephone: attr('string'),
    ZipCode: attr('string'),
    customer: attr({
      defaultValue: function defaultValue(site) {
        var customerId = "site-".concat(site.id, "-customer");
        return site.get('store').createRecord('customer', {
          id: customerId,
          siteId: site.id
        });
      }
    }),
    trip_stops: hasMany('trip-stop', {
      async: false,
      polymorphic: true
    }),
    storages: hasMany('storage', {
      async: true
    }),
    site_remarks: hasMany('site-remark'),
    isStale: function isStale(referenceTime) {
      var staleStorages = this.get('storages').filter(function (s) {
        return s.isStale(referenceTime);
      });
      return (0, _utils.isPresent)(staleStorages);
    },
    isDeliveryLocation: (0, _computed.equal)('SiteType', 'S'),
    fullAddress: (0, _object.computed)('Address', 'ZipCode', 'City', 'County', 'CountryCode', function () {
      return [this.get('Address'), [this.get('ZipCode'), this.get('City')].filter(function (stringPart) {
        return stringPart;
      }).join(' '), [this.get('County'), this.get('CountryCode')].filter(function (stringPart) {
        return stringPart;
      }).join(' ')].filter(function (stringPart) {
        return stringPart;
      }).join(',\n');
    }),
    telephoneNumbered: (0, _object.computed)('Telephone', function () {
      if (this.get('Telephone')) {
        return this.get('Telephone').replace(/[^\d]/g, '');
      }
    }),
    generateDeliveryTicket: (0, _computed.alias)('customer.generateDeliveryTicket'),
    useFlowMeter: (0, _computed.alias)('customer.useFlowMeter')
  });

  _exports.default = _default;
});