define("bottomline-execution/templates/trip-stops/new/special-activity", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "az4Te98O",
    "block": "[[[3,\" Views\"],[1,\"\\n\"],[10,0],[14,0,\"views\"],[12],[1,\"\\n  \"],[3,\" Left view\"],[1,\"\\n  \"],[10,0],[14,0,\"view view-left\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"      \"],[10,0],[14,0,\"center\"],[12],[1,\"\\n        \"],[1,[28,[35,1],null,[[\"key\"],[\"add_special_activity\"]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[3,\" Right view, it is main view\"],[1,\"\\n  \"],[10,0],[14,0,\"view view-main\"],[12],[1,\"\\n    \"],[3,\" Pages\"],[1,\"\\n    \"],[10,0],[14,0,\"pages\"],[12],[1,\"\\n      \"],[3,\" Page, data-page contains page name\"],[1,\"\\n      \"],[10,0],[14,0,\"page\"],[12],[1,\"\\n        \"],[3,\" Scrollable page content\"],[1,\"\\n        \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n          \"],[1,[33,2,[\"ConfirmedStartDT\"]]],[1,\"\\n          \"],[1,[33,2,[\"ConfirmedEndDT\"]]],[1,\"\\n\\n        \"],[13],[1,\"\\n\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"f7-inner-navbar\",\"editable-t\",\"newTripStop\"]]",
    "moduleName": "bottomline-execution/templates/trip-stops/new/special-activity.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});