define("bottomline-execution/templates/components/material-on-truck", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Xyb9oL1e",
    "block": "[[[41,[33,1,[\"compartments\"]],[[[1,\"  \"],[10,\"table\"],[12],[1,\"\\n    \"],[10,\"tr\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"shift\"],[[33,3]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,4],null,[[\"key\"],[\"column.Material\"]]]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,4],null,[[\"key\"],[\"column.CurrentQty\"]]]],[13],[1,\"\\n    \"],[10,\"th\"],[12],[1,[28,[35,4],null,[[\"key\"],[\"column.Site\"]]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"      \"],[1,[28,[35,8],null,[[\"inventory\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"inventory\"],false,[\"if\",\"truckSettings\",\"truck-inventory\",\"shift\",\"editable-t\",\"each\",\"-track-array\",\"materialInventoriesWithTripStopNeed\",\"material-on-truck/item\"]]",
    "moduleName": "bottomline-execution/templates/components/material-on-truck.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});