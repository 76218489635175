define("bottomline-execution/authorizers/auth0", ["exports", "ember-simple-auth/authorizers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    authorize: function authorize(session_data, callback) {
      callback('Authorization', "Bearer ".concat(session_data.access_token));
    }
  });

  _exports.default = _default;
});