define("bottomline-execution/templates/components/shift-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "R7V293Tm",
    "block": "[[[10,0],[14,0,\"list-block shift-list\"],[12],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"shift\",\"on-item-click\",\"on-shift-trip-navigate-click\",\"on-trip-item-click\",\"on-state-button-click\",\"isSelected\",\"appState\"],[[30,1],[28,[37,4],[[30,0],\"selectShift\"],null],[28,[37,4],[[30,0],\"onShiftTripNavigateClick\"],null],[28,[37,4],[[30,0],\"selectTrip\"],null],[28,[37,4],[[30,0],\"updateState\"],null],[28,[37,5],[[33,6,[\"selectedShift\"]],[30,1,[\"id\"]]],null],[33,6]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"shift\"],false,[\"each\",\"-track-array\",\"sortedShifts\",\"shift-item\",\"action\",\"eq\",\"appState\"]]",
    "moduleName": "bottomline-execution/templates/components/shift-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});