define("bottomline-execution/components/trip-stop-storage-line", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _computed, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    tagName: 'tr',
    classNames: ['trip-stop-storage'],
    classNameBindings: ['isSelected'],
    compartments: (0, _computed.alias)('truckSettings.compartments'),
    showTemperatureDensity15Liters: (0, _computed.alias)('model.showTemperatureDensity15Liters'),
    loadTicketnbPerTank: (0, _computed.alias)('truckSettings.load_ticketnb_per_tank'),
    isSelected: (0, _object.computed)('activeTripStopStorage', function () {
      return this.get('activeTripStopStorage.id') === this.get('tripStopStorage.id');
    }),
    click: function click() {
      if (this.get('compartments')) {
        var tripStopStorageId = this.get('tripStopStorage.id') || -1;
        this.get('parentController').send('selectLoadTripStopStorage', tripStopStorageId);
      }
    },
    willInsertElement: function willInsertElement() {
      this.get('tripStopStorage').calculateTotalCompartmentQty();
    }
  });

  _exports.default = _default;
});