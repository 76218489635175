define("bottomline-execution/templates/components/input-number-spinner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JhPhgUdG",
    "block": "[[[10,0],[14,0,\"input-number-spinner\"],[15,\"disabled\",[36,0]],[12],[1,\"\\n  \"],[10,\"button\"],[14,0,\"button button-small input-number-spinner__decrease-button\"],[15,\"onClick\",[28,[37,1],[[30,0],\"decreaseValue\"],null]],[12],[1,\"-\"],[13],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"value\",\"class\",\"placeholder\",\"min\",\"max\",\"inputmode\",\"key-up\",\"focus-out\"],[[33,3],\"input-number-spinner__input\",[33,4],[33,5],[33,6],\"decimal\",[28,[37,1],[[30,0],\"changeValue\"],null],[28,[37,1],[[30,0],\"ensureNumericality\"],null]]]]],[1,\"\\n  \"],[10,\"button\"],[14,0,\"button button-small input-number-spinner__increase-button\"],[15,\"onClick\",[28,[37,1],[[30,0],\"increaseValue\"],null]],[12],[1,\"+\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"disabled\",\"action\",\"input\",\"value\",\"placeholder\",\"min\",\"max\"]]",
    "moduleName": "bottomline-execution/templates/components/input-number-spinner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});