define("bottomline-execution/templates/components/input-number-base", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0yC+0UAM",
    "block": "[[[10,0],[14,0,\"input-number-base\"],[15,\"disabled\",[36,0]],[12],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"value\",\"class\",\"placeholder\",\"min\",\"max\",\"inputmode\",\"key-up\",\"focus-out\"],[[33,2],\"input-number-base__input\",[33,3],[33,4],[33,5],\"decimal\",[28,[37,6],[[30,0],\"changeValue\"],null],[28,[37,6],[[30,0],\"ensureNumericality\"],null]]]]],[1,\"\\n\"],[13]],[],false,[\"disabled\",\"input\",\"value\",\"placeholder\",\"min\",\"max\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/input-number-base.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});