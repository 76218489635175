define("bottomline-execution/templates/components/details-end-inventory", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Xxssy0fC",
    "block": "[[[10,\"table\"],[12],[1,\"\\n  \"],[10,\"tr\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"      \"],[1,[28,[35,3],null,[[\"truckStorage\",\"trip-stop\",\"tripStopStorage\",\"truckSettings\"],[[30,1],[33,4],[33,5],[33,6]]]]],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"compartment\"],false,[\"each\",\"-track-array\",\"compartments\",\"truck-storage-end-inventory\",\"trip-stop\",\"tripStopStorage\",\"truckSettings\"]]",
    "moduleName": "bottomline-execution/templates/components/details-end-inventory.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});