define("bottomline-execution/services/vehicle-storages", ["exports", "@ember/object/computed", "@ember/service", "bottomline-execution/utils/alpha-numeric-sort"], function (_exports, _computed, _service, _alphaNumericSort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    truckStorages: function truckStorages() {
      return this._vehicleStorages('truck');
    },
    trailerStorages: function trailerStorages() {
      return this._vehicleStorages('trailer');
    },
    hasTruckAndTrailerStorages: function hasTruckAndTrailerStorages() {
      return this.truckStorages().length && this.trailerStorages().length;
    },
    hasTruckStorages: function hasTruckStorages() {
      return this.truckStorages().length > 0;
    },
    hasTrailerStorages: function hasTrailerStorages() {
      return this.trailerStorages().length > 0;
    },
    _vehicleStorages: function _vehicleStorages() {
      var vehicle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'truck';
      return this.get('store').peekAll('truck-storage').filter(function (truckStorage) {
        return truckStorage.get('vehicle_type') === vehicle;
      }).sort(function (a, b) {
        return (0, _alphaNumericSort.alphaNumericSort)(a.get('TruckStorageID'), b.get('TruckStorageID'));
      }).reverse();
    }
  });

  _exports.default = _default;
});