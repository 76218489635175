define("bottomline-execution/templates/trip-stops/show/scan", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j/XXWzER",
    "block": "[[[10,0],[14,0,\"views\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"view\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"pages\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"page\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"page-content\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"card\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"card-header\"],[12],[1,\"\\n              \"],[10,1],[12],[1,[28,[35,0],null,[[\"key\"],[\"scan_title\"]]]],[13],[1,\"\\n\\n              \"],[10,\"button\"],[14,0,\"button button-fill button-fill color-red\"],[15,\"onClick\",[28,[37,1],[[30,0],\"cancel\"],null]],[14,4,\"button\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"cancel\"]]]],[13],[1,\"\\n              \"],[10,\"button\"],[14,0,\"button button-fill color-green\"],[15,\"onClick\",[28,[37,1],[[30,0],\"scan\"],null]],[14,4,\"button\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"scan_retry\"]]]],[13],[1,\"\\n\\n\\n\"],[41,[33,3],[[[1,\"                \"],[10,\"button\"],[14,0,\"button button-big button-fill color-green\"],[15,\"onClick\",[28,[37,1],[[30,0],\"confirm\"],null]],[14,4,\"button\"],[12],[1,[28,[35,0],null,[[\"key\"],[\"scan_confirm\"]]]],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n              \"],[10,0],[14,0,\"card-content-inner\"],[12],[1,\"\\n                \"],[10,0],[14,1,\"scan-preview\"],[14,0,\"scan-preview\"],[12],[1,\"\\n\"],[41,[33,3],[[[1,\"                    \"],[10,\"img\"],[15,\"src\",[29,[\"data:image/jpeg;base64,\",[36,3]]]],[14,5,\"width: 100%\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\",\"action\",\"if\",\"previewImage\"]]",
    "moduleName": "bottomline-execution/templates/trip-stops/show/scan.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});