define("bottomline-execution/validations/trip-stop-storage-validations", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "ember-cp-validations"], function (_exports, _computed, _object, _utils, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Validations = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    endInventoryQty: {
      description: 'Post dip',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        positive: true
      })]
    },
    endInventoryQtyValid: {
      disabled: (0, _object.computed)(function () {
        return (0, _utils.isBlank)(this.model.get('deliveryTolerance'));
      }),
      validators: [(0, _emberCpValidations.validator)(function (_value, options, model) {
        var deviation = model.get('endInventoryQty') - model.get('confirmedQty') - model.get('startInventoryQty');
        var validDeviation = Math.abs(deviation) <= model.get('deliveryTolerance');
        return validDeviation || options.message;
      }, {
        message: (0, _object.computed)(function () {
          return "".concat(this.model.get('storage.SequenceNb'), " ").concat(this.model.get('material.MaterialNameShort'));
        }),
        dependentKeys: ['model.confirmedQty', 'model.endInventoryQty', 'model.startInventoryQty']
      })]
    },
    hasTicketNumberOnConfirmedQty: {
      disabled: (0, _computed.equal)('model.plannedQty', 0),
      validators: [(0, _emberCpValidations.validator)(function (_value, options, model) {
        var missingTicketNumber = (0, _utils.isPresent)(model.get('confirmedQty')) && (0, _utils.isBlank)(model.get('TicketNb'));
        return !missingTicketNumber || options.message;
      }, {
        message: 'Ticket number is missing',
        dependentKeys: ['model.confirmedQty', 'model.TicketNb']
      })]
    },
    postDipUllage: {
      description: 'Post ullage',
      validators: [(0, _emberCpValidations.validator)('number', {
        allowNone: false,
        gt: 0
      })]
    },
    postDipUllageValid: {
      disabled: (0, _object.computed)('model.postDipUllage', function () {
        return (0, _utils.isBlank)(this.model.get('postDipUllage')) || (0, _utils.isBlank)(this.model.get('storage.usableStorageQty'));
      }),
      validators: [(0, _emberCpValidations.validator)(function (_value, options, model) {
        var usableStorageQty = model.get('storage.usableStorageQty');
        var validPostUllage = model.get('postDipUllage') <= usableStorageQty;
        return validPostUllage || options.message;
      }, {
        message: 'Post ullage exceeds usable tank capacity'
      })]
    }
  });
  _exports.Validations = Validations;
});