define("bottomline-execution/templates/components/material-on-truck/item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YuCx3MD4",
    "block": "[[[10,\"tr\"],[15,5,[33,0,[\"material\",\"style\"]]],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"material-on-truck__table-cell\"],[12],[1,\"\\n    \"],[1,[33,0,[\"material\",\"MaterialNameShort\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[14,0,\"material-on-truck__table-cell\"],[12],[1,\"\\n    \"],[1,[33,0,[\"currentQty\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[14,0,\"material-on-truck__table-cell\"],[12],[1,\"\\n    \"],[1,[33,0,[\"neededQty\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"inventory\"]]",
    "moduleName": "bottomline-execution/templates/components/material-on-truck/item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});