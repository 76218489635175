define("bottomline-execution/services/app-configuration", ["exports", "@ember/object/computed", "@ember/object", "@ember/service"], function (_exports, _computed, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    state: (0, _object.computed)(function () {
      return this.get('store').peekRecord('state', 1);
    }),
    findState: (0, _object.computed)(function () {
      return this.get('store').find('state', 1);
    }),
    truckSettings: (0, _computed.alias)('state.truckSettings')
  });

  _exports.default = _default;
});