define("bottomline-execution/mirage/factories/shift", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    id: function id(i) {
      return "".concat(i + 1);
    },
    state: 'planned',
    truck_availability_id: function truck_availability_id(i) {
      return "".concat(i);
    },
    shift_id: function shift_id(i) {
      return "".concat(i);
    },
    trip_date: function trip_date() {
      return Date();
    },
    planned_start_dt: function planned_start_dt() {
      return Date();
    },
    planned_end_dt: function planned_end_dt() {
      return Date();
    },
    shift_seq_nb: 1,
    preloaded: false,
    time_stamp: '12345',
    status: 1,
    truck: '2345',
    trip_ids: [],
    truck_inventories: []
  });

  _exports.default = _default;
});