define("bottomline-execution/models/be/customer", ["exports", "bottomline-execution/models/customer", "ember-data", "@ember/object/computed", "@ember/object"], function (_exports, _customer, _emberData, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _customer.default.extend({
    siteId: attr('string'),
    site: (0, _object.computed)('siteId', function () {
      return this.get('store').peekRecord('site', this.get('siteId'));
    }),
    generateDeliveryTicket: (0, _computed.or)('_isSpecialLoadId', '_unrestrictedDeliveryTickets'),
    useFlowMeter: (0, _computed.and)('_needsSpecialId', '_hasSpecialId'),
    canAddStopFromOrder: (0, _computed.or)('_isSpecialLoadId', '_unrestrictedAddStopFromOrder'),
    _needsSpecialId: (0, _computed.alias)('truckSettings.customer_type_with_flow_meter'),
    _hasSpecialId: (0, _computed.or)('_isSpecialLoadId', '_isSpecialExtInvoiceDebtorId'),
    _isSpecialLoadId: (0, _computed.equal)('site.LoadingID', '1000'),
    _isSpecialExtInvoiceDebtorId: (0, _computed.equal)('site.ExtInvoiceDebtorID', '999')
  });

  _exports.default = _default;
});