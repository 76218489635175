define("bottomline-execution/mixins/raw-app-state", ["exports", "@ember/object/mixin", "@ember/object"], function (_exports, _mixin, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    useBxBackend: (0, _object.computed)(function () {
      return this._appState().backend_type === 'bx';
    }).volatile(),
    _appState: function _appState() {
      if (localStorage.state) {
        return JSON.parse(localStorage.state).state.records[1];
      } else {
        return new _object.default();
      }
    }
  });

  _exports.default = _default;
});