define("bottomline-execution/routes/shifts/index", ["exports", "@ember/object/computed", "@ember/routing/route", "@ember/service", "@ember/utils", "rsvp", "ember-simple-auth/mixins/authenticated-route-mixin", "bottomline-execution/mixins/storages-loader"], function (_exports, _computed, _route, _service, _utils, _rsvp, _authenticatedRouteMixin, _storagesLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, _storagesLoader.default, {
    queryParams: {
      truckId: {
        refreshModel: true
      }
    },
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    internetConnection: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    model: function model() {
      var _this = this;

      var shifts = this._interceptedNetworkRequest();

      if ((0, _utils.isPresent)(shifts)) return shifts;
      return new _rsvp.Promise(function (resolve) {
        _this._clearTruckInventory();

        _this.get('store').query('shift', {
          truck: _this.get('appState.currentTruck')
        }).then(function (shifts) {
          _this._loadRecentTripStop(shifts);

          _this._warnTripLoadingIncomplete();

          _this.controllerFor('application').set('siteStoragesLoader', _this._getSiteStoragesLoader(shifts));

          _this._ensureSelectedShift(shifts);

          return _this._updateAppState(shifts);
        }).then(function (shifts) {
          resolve(shifts);
        });
      });
    },
    setupController: function setupController(controller, model) {
      this.store.set('shiftMeta', model.get('meta'));
      controller.set('model', model);
    },
    _interceptedNetworkRequest: function _interceptedNetworkRequest() {
      var controller = this.controllerFor(this.routeName);
      var returningFromTripsIndex = controller.target.currentRouteName == 'trips.index';
      var offline = this.get('appState.offline');
      if (returningFromTripsIndex && offline) return this.get('store').peekAll('shift');
    },
    _clearTruckInventory: function _clearTruckInventory() {
      var _this2 = this;

      this.get('store').peekAll('truck-inventory').reject(function (e) {
        return !/ember/.test(e.id) || e.id === null;
      }).forEach(function (rec) {
        return _this2.store.deleteRecord(rec);
      });
    },
    _getSiteStoragesLoader: function _getSiteStoragesLoader(shifts) {
      var _this3 = this;

      if (this.get('appState.useBeBackend')) {
        return this._loadShiftsData(shifts).then(function () {
          return _this3.loadAllSiteStorages();
        });
      } else {
        // For BX shift, trips, and trip stops are loaded in the corresponding BX adapters
        return this.loadAllSiteStorages();
      }
    },
    _ensureSelectedShift: function _ensureSelectedShift(shifts) {
      if ((0, _utils.isBlank)(this.get('appState.selectedShift'))) {
        this.get('appState').set('selectedShift', shifts.get('firstObject.id'));
      }
    },
    _updateAppState: function _updateAppState(shifts) {
      var _this4 = this;

      return new _rsvp.Promise(function (resolve) {
        var appState = _this4.get('appState');

        appState.setProperties({
          anyShiftStarted: shifts.filterBy('state', 'started').length > 0,
          currentTrailer: _this4.get('appState.shift.TrailerID')
        });
        appState.save().then(function () {
          resolve(shifts);
        });
      });
    },
    _loadRecentTripStop: function _loadRecentTripStop(shifts) {
      var recentLoadTripStopPayload = shifts.get('meta').recentLoadTripStop;

      if ((0, _utils.isPresent)(recentLoadTripStopPayload)) {
        var tripStopId = recentLoadTripStopPayload.trip_stop.id;
        return this.store.findRecord('trip-stop', tripStopId);
      } else {
        return _rsvp.Promise.resolve();
      }
    },
    _loadShiftsData: function _loadShiftsData(shifts) {
      return _rsvp.Promise.all(shifts.map(function (shift) {
        return shift.get('trips');
      })).then(function (shiftsTrips) {
        return _rsvp.Promise.all(shiftsTrips.flat().map(function (trip) {
          return trip.get('trip_stops');
        }));
      });
    },
    _warnTripLoadingIncomplete: function _warnTripLoadingIncomplete() {
      if (window.BottomlineExecutionTripsLoadingIncomplete) {
        this.get('toast').error("Not all trips are loaded correctly");
        window.BottomlineExecutionTripsLoadingIncomplete = undefined;
      }
    }
  });

  _exports.default = _default;
});