define("bottomline-execution/templates/components/authorize-form/pre-auth/tank-available-qty", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RfRNhxga",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"item-input\"],[12],[1,[28,[35,2],null,[[\"numericValue\"],[[33,3]]]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"editableUllage\",\"input-number\",\"availableQty\"]]",
    "moduleName": "bottomline-execution/templates/components/authorize-form/pre-auth/tank-available-qty.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});