define("bottomline-execution/models/order", ["exports", "@ember/object/computed", "@ember/object", "ember-data"], function (_exports, _computed, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;

  var _default = Model.extend({
    OrderID: attr('string'),
    ExtOrderID: attr('string'),
    TruckID: attr('string'),
    StartDT: attr('date'),
    OrderDt: attr('date'),
    OrderSource: attr('string'),
    OrderQty: attr('number'),
    order_remarks: hasMany('order_remark', {
      async: false
    }),
    order_details: hasMany('order-detail', {
      async: true
    }),
    prepareForSerialization: function prepareForSerialization() {
      var _this = this;

      return this.get('store').query('order-detail', {
        order_id: this.id
      }).then(function (orderDetails) {
        _this.set('order_details', orderDetails);
      });
    },
    isAutomatic: (0, _object.computed)('OrderSource', function () {
      return ['A', 'F'].includes(this.get('OrderSource'));
    }),
    isManual: (0, _computed.not)('isAutomatic')
  });

  _exports.default = _default;
});