define("bottomline-execution/authorizers/devise", ["exports", "ember-simple-auth/authorizers/devise", "bottomline-execution/config/environment"], function (_exports, _devise, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _devise.default.extend({
    identificationAttributeName: _environment.default.devise.identificationAttributeName,
    tokenAttributeName: _environment.default.devise.tokenAttributeName
  });

  _exports.default = _default;
});