define("bottomline-execution/templates/components/trip-state-updater", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "nTU1auNZ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[10,\"button\"],[15,0,[29,[[52,[30,1,[\"disabled\"]],\"disabled\"],\" \",[52,[30,1,[\"isHidden\"]],\"is-hidden\"],\" button button-raised\"]]],[15,\"onClick\",[28,[37,4],[[30,0],[30,1,[\"action\"]],[30,1],[33,5]],null]],[12],[1,[28,[35,6],null,[[\"key\"],[[30,1,[\"action\"]]]]]],[13],[1,\"\\n\"]],[1]],null]],[\"state\"],false,[\"each\",\"-track-array\",\"nextStates\",\"if\",\"action\",\"trip\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/trip-state-updater.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});