define("bottomline-execution/components/toggle-show", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service", "@ember/object/evented"], function (_exports, _computed, _component, _object, _service, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    incrementMediator: 0,
    title: '',
    translate: true,
    classNames: ['card'],
    padContent: true,
    hide: null,
    showContent: true,
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    toggleName: (0, _object.computed)(function () {
      return "toggleShow".concat(this.get('title').classify());
    }),
    showContentChanged: (0, _evented.on)('init', (0, _object.observer)('incrementMediator', 'hide', function () {
      var togglePropertyState = this.get('appState').get(this.get('toggleName'));
      var hide = this.get('hide');
      this.set('showContent', hide === null ? togglePropertyState : false);
    })),
    actions: {
      toggle: function toggle() {
        var _this = this;

        this.get('appState').toggleProperty(this.get('toggleName'));
        this.get('appState').save().then(function () {
          _this.incrementProperty('incrementMediator');
        });
      }
    }
  });

  _exports.default = _default;
});