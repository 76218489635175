define("bottomline-execution/models/material", ["exports", "ember", "@ember/object", "ember-data"], function (_exports, _ember, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var htmlSafe = _ember.default.String.htmlSafe;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;

  var _default = Model.extend({
    MaterialNameShort: attr('string'),
    MaterialNameLong: attr('string'),
    ReplaceableYN: attr('boolean'),
    MaterialDensity: attr('number'),
    DeliveryCompatibility: attr('string'),
    foreground_color: attr('string'),
    background_color: attr('string'),
    meter_material_number: attr('string'),
    material_delivery_tolerance: attr('number'),
    style: (0, _object.computed)('background_color,foreground_color', function () {
      var backgroundColor = this.get('background_color') || 'lightgrey';
      var foregroundColor = this.get('foreground_color') || '#595959';
      return htmlSafe("background-color: ".concat(backgroundColor, "; color: ").concat(foregroundColor));
    }),
    compatibleMaterials: (0, _object.computed)(function () {
      return this.get('store').peekAll('material').filterBy('DeliveryCompatibility', this.get('id'));
    })
  });

  _exports.default = _default;
});