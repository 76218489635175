define("bottomline-execution/templates/components/end-inventory/trip-stop-storage-line", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "djZHKn9f",
    "block": "[[[10,\"td\"],[15,5,[33,0,[\"material\",\"style\"]]],[12],[1,\"\\n  \"],[1,[33,0,[\"material\",\"MaterialNameShort\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[12],[1,\"\\n  \"],[1,[33,0,[\"currentInventory\"]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"list-block\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"numericValue\",\"disabled\"],[[33,0,[\"confirmedQty\"]],[33,2]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[33,2],[[[1,\"  \"],[10,\"td\"],[12],[1,\"\\n    \"],[10,\"button\"],[14,0,\"button\"],[15,\"onClick\",[28,[37,4],[[30,0],\"emptyTripStopTruckStorages\",[33,0]],null]],[12],[1,[28,[35,5],null,[[\"key\"],[\"empty_compartments\"]]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"tripStopStorage\",\"input-number\",\"isEnabledCompartments\",\"if\",\"action\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/end-inventory/trip-stop-storage-line.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});