define("bottomline-execution/controllers/emis-debug", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    message: '',
    actions: {
      add: function add() {
        if (this.get('message').length === 0) {
          return;
        }

        this.get('model').sendMessage(this.get('message'));
        this.set('message', '');
      },
      example: function example() {
        this.get('model').sendMessage('SET,ADMIN,PROTOCOL,Ping="COM-Init"').then(function (response) {
          console.log(response);
        });
      },
      getError: function getError() {
        this.get('model').sendMessage('REQUEST,ADMIN,STATUS,LastError').then(function (response) {
          console.log(response);
        });
      },
      reset: function reset() {
        this.get('model').sendMessage('SET,ADMIN,STATUS,RESET="1"').then(function (response) {
          console.log(response);
        });
      },
      mode: function mode() {
        this.get('model').sendMessage('REQUEST,ADMIN,STATUS,Mode').then(function (response) {
          console.log(response);
        });
      },
      gpstest: function gpstest() {
        this.get('model').sendMessage('message', 'REQUEST,GPS,DATA,Lat').then(function (response) {
          console.log(response);
        });
        this.get('model').sendMessage('message', 'REQUEST,GPS,DATA,Lon').then(function (response) {
          console.log(response);
        });
      }
    }
  });

  _exports.default = _default;
});