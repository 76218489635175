define("bottomline-execution/controllers/trips/index", ["exports", "@ember/object/computed", "@ember/array", "@ember/controller", "@ember/object", "@ember/service", "rsvp", "@ember/utils", "bottomline-execution/mixins/storages-loader", "bottomline-execution/mixins/trip-sorter"], function (_exports, _computed, _array, _controller, _object, _service, _rsvp, _utils, _storagesLoader, _tripSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend(_storagesLoader.default, _tripSorter.default, {
    i18n: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    ajax: (0, _service.inject)(),
    selectedTrip: null,
    selectedTripStopId: (0, _computed.alias)('appState.selectedTripStop'),
    selectedTripStop: (0, _object.computed)('appState.selectedTripStop', function () {
      if ((0, _utils.isPresent)(this.get('selectedTripStopId'))) {
        return this.get('store').peekRecord('trip-stop', this.get('selectedTripStopId'));
      } else {
        return null;
      }
    }),
    addActivity: false,
    invertedWidth: false,
    isVisible: true,
    defaultMode: true,
    isOpeningTicket: false,
    isOpeningReport: false,
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    online: (0, _computed.alias)('appState.online'),
    offline: (0, _computed.not)('online'),
    currentTruck: (0, _computed.alias)('appState.currentTruck'),
    askForOdometer: (0, _computed.alias)('appConfiguration.truckSettings.odometer_on_trip_level'),
    useMaterialCalculation: (0, _computed.alias)('appConfiguration.truckSettings.use_material_calculation'),
    showAtgInformation: (0, _computed.alias)('appConfiguration.truckSettings.show_atg_information'),
    deprecatedSourceForTripReport: (0, _object.computed)(function () {
      return this.get('appConfiguration.truckSettings.deprecated_source_trip_report');
    }),
    deprecatedSourceForDriverReport: (0, _object.computed)(function () {
      return this.get('appConfiguration.truckSettings.deprecated_source_driver_report');
    }),
    trips: (0, _computed.alias)('model'),
    startedTrip: (0, _object.computed)('model.@each.state', function () {
      return this.get('model').toArray().find(function (trip) {
        return trip.get('state') === 'started';
      });
    }),
    tripIsStarted: (0, _object.computed)('startedTrip', function () {
      return (0, _utils.isPresent)(this.get('startedTrip'));
    }),
    selectedTripStopTripIsStarted: (0, _object.computed)('selectedTripStop.trip.state', 'startedTrip', function () {
      if ((0, _utils.isBlank)(this.get('selectedTripStop'))) return false;
      return this.get('startedTrip') && this.get('selectedTripStop.trip.state') === 'started';
    }),
    tanksWithATGProblemsPresent: (0, _computed.notEmpty)('tanksWithATGProblems'),
    isATGStop: (0, _computed.alias)('selectedTripStop.site.isATGStop'),
    showATGInfoForDelivery: (0, _computed.alias)('selectedTripStop.site.isDeliveryLocation'),
    tanksWithATGProblems: (0, _computed.alias)('selectedTripStop.trip.tanksWithATGProblems'),
    sortedTripStopStorages: (0, _object.computed)('selectedTripStop.trip_stop_storages.@each', function () {
      return this.get('selectedTripStop.trip_stop_storages').sortBy('storage.SequenceNb');
    }),
    remarksForDriverPresent: (0, _object.computed)('selectedTripStop.hasRemarks', function () {
      return (0, _utils.isPresent)(this.get('selectedTripStop')) && this.get('selectedTripStop.hasRemarks');
    }),
    selectedMarkers: (0, _object.computed)('selectedTripStop', function () {
      return (0, _array.A)([this.get('selectedTripStop')]);
    }),
    tripMarkers: (0, _object.computed)('selectedTripStop.trip.displayableTripStops.[]', 'selectedTripStop.trip.isFulFilled', function () {
      return this.get('selectedTripStop.trip.displayableTripStops');
    }),
    points: (0, _object.computed)('model', 'model.@each.trip_stops', 'model.isFulfilled', function () {
      var tripStops = [];
      this.get('model').forEach(function (trip) {
        tripStops.concat(trip.get('tripStops'));
      });
      return tripStops;
    }),
    shift: (0, _object.computed)('model.firstObject', function () {
      return this.get('model.firstObject.shift');
    }),
    shiftStateStarted: (0, _object.computed)('model.firstObject.shift.state', function () {
      return this.get('model.firstObject.shift.state') === 'started';
    }),
    canChangeTerminal: (0, _object.computed)('startedTrip.hasLoadStop', 'startedTrip.loadStopIsConfirmed', function () {
      return this.get('startedTrip.hasLoadStop') && !this.get('startedTrip.loadStopIsConfirmed');
    }),
    appController: (0, _controller.inject)('application'),
    siteStoragesLoader: (0, _computed.alias)('appController.siteStoragesLoader'),
    actions: {
      toggleShowTrip: function toggleShowTrip(trip) {
        trip.toggleProperty('isShown');
      },
      toggleInvertedWidth: function toggleInvertedWidth(val) {
        this.set('invertedWidth', val);
      },
      addSpecialActivity: function addSpecialActivity() {
        this.toggleProperty('addActivity');
      },
      updateState: function updateState(action, model) {
        var _this = this;

        this.get('appState').setSelectedTrip(model.id);

        switch (action.state) {
          case 'started':
            // Start and Restart
            this._askForOdometerStart(model).then(function () {
              _this._retrieveStaleSiteStorages(model);

              switch (action.action) {
                case 'start':
                  model.start();
                  break;

                case 'restart':
                  model.restart();
                  break;
              }

              model.get('shift.trips').forEach(function (trip) {
                return trip.notifyPropertyChange('state');
              });
            });

            break;

          case 'finished':
            // Finish
            this._askForOdometerEnd(model).then(function () {
              model.finish();
            });

            break;

          case 'planned':
            // Cancel
            model.cancel();
            break;
        }
      },
      viewTicket: function viewTicket(trip) {
        var _this2 = this;

        var pdfDocument = this.get('store').createRecord('pdf-document', {
          documentType: 'tripReport',
          modelId: trip.get('id')
        });

        var flagTripReportOpening = function flagTripReportOpening(opening) {
          _this2.set('isOpeningTicket', opening);
        };

        var errorOpeningDocument = function errorOpeningDocument(error) {
          _this2._errorOpeningDocument(error);
        };

        pdfDocument.open({
          signalOpening: flagTripReportOpening,
          showWarning: errorOpeningDocument
        });
      },
      viewReport: function viewReport(trip) {
        var _this3 = this;

        var flagDriverReportOpening = function flagDriverReportOpening(opening) {
          _this3.set('isOpeningReport', opening);
        };

        var errorOpeningDocument = function errorOpeningDocument(error) {
          _this3._errorOpeningDocument(error);
        };

        this.get('store').query('pdf-document', {
          documentType: 'driverReport',
          modelId: trip.get('id')
        }).then(function (documentsList) {
          // Drop documents with description 'ticket', there is a separate button for that document type
          documentsList = documentsList.rejectBy('description', 'ticket');
          var documentOpenOptions = {
            signalOpening: flagDriverReportOpening,
            showWarning: errorOpeningDocument
          };

          switch (documentsList.length) {
            case 0:
              _this3.get('toast').info(_this3.get('i18n').t('trip.documents.notfound'));

              break;

            case 1:
              documentsList.get('firstObject').open(documentOpenOptions);
              break;

            default:
              _this3._showDocumentSelector(documentsList, documentOpenOptions);

          }
        }).catch(function (error) {
          _this3._errorOpeningDocument(error);
        });
      },
      viewRemarks: function viewRemarks(trip) {
        this.set('selectedTrip', trip);
        this.set('defaultMode', false);
      },
      closeRemarks: function closeRemarks() {
        this.set('defaultMode', true);
      },
      skipTrip: function skipTrip(trip) {
        trip.get('tripStops').forEach(function (tripStop) {
          return tripStop.set('DriverRemarks', 'Skipped');
        });
        trip.set('state', 'finished');
        trip.finish();
      },
      syncTrip: function syncTrip(trip) {
        var _this4 = this;

        var tripId = trip && trip.id;

        if (!tripId || !trip.get('isShown')) {
          return;
        }

        trip.set('beingProcessed', true);
        trip.reload().then(function (trip) {
          trip.calculateEndInventoryAndCorrection();
          return _this4._retrieveUnloadSiteStorages(trip);
        }).finally(function () {
          trip.set('beingProcessed', false);
        });
      }
    },
    _retrieveUnloadSiteStorages: function _retrieveUnloadSiteStorages(trip) {
      if (this.get('online')) {
        var siteIds = trip.deliveryStops().mapBy('site.id').uniq();
        return this.loadSiteStorages(siteIds);
      } else {
        return Promise.resolve();
      }
    },
    _retrieveStaleSiteStorages: function _retrieveStaleSiteStorages(trip) {
      var siteIds = trip.sitesWithStaleStorages().mapBy('SiteID');

      if ((0, _utils.isPresent)(siteIds)) {
        this.loadSiteStorages(siteIds);
      }
    },
    getAllTripStops: function getAllTripStops() {
      var _this5 = this;

      return this.get('store').peekAll('trip-stop').filter(function (tripStop) {
        return tripStop.get('tripID') === _this5.get('trip').id;
      });
    },
    _errorOpeningDocument: function _errorOpeningDocument(error) {
      this.get('toast').error(this.get('i18n').t('trip.documents.error') + error.message);
    },
    _showDocumentSelector: function _showDocumentSelector(documentsList, documentOpenOptions) {
      var _this6 = this;

      var openDocument = function openDocument(document) {
        try {
          document.open(documentOpenOptions);
        } catch (error) {
          _this6._errorOpeningDocument(error);
        }
      };

      var documentButtons = documentsList.map(function (document) {
        return {
          text: _this6.get('i18n').t("trip.documents.".concat(document.description), {
            default: document.description
          }),
          onClick: function onClick() {
            openDocument(document);
          }
        };
      });
      this.get('f7').modal({
        text: this.get('i18n').t('trip.documents.dialogText'),
        verticalButtons: true,
        buttons: documentButtons
      });
    },
    _askForOdometerEnd: function _askForOdometerEnd(trip) {
      var deferred = _rsvp.default.defer();

      if (!this.get('askForOdometer')) {
        deferred.resolve();
        return deferred.promise;
      }

      var odometer = trip.get('OdometerEnd');
      this.get('f7').extdPrompt(this.get('i18n').t('end_of_trip'), this.get('i18n').t('form_odometer'), 'number', odometer, function (error) {
        if (error !== 'undefined' && error !== '' && error !== '0') {
          if (error !== odometer) {
            trip.set('OdometerEnd', error);
          }

          deferred.resolve();
        }
      }, function () {
        return deferred.reject();
      });
      return deferred.promise;
    },
    _askForOdometerStart: function _askForOdometerStart(trip) {
      var deferred = _rsvp.default.defer();

      if (!this.get('askForOdometer')) {
        deferred.resolve();
        return deferred.promise;
      }

      var odometer = trip.get('OdometerStart');
      this.get('f7').extdPrompt(this.get('i18n').t('start_of_trip'), this.get('i18n').t('form_odometer'), 'number', odometer, function (error) {
        if (error !== 'undefined' && error !== '' && error !== '0') {
          if (error !== odometer) {
            trip.set('OdometerStart', error);
          }

          deferred.resolve();
        }
      }, function () {
        return deferred.reject();
      });
      return deferred.promise;
    }
  });

  _exports.default = _default;
});