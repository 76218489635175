define("bottomline-execution/templates/components/barrel-count-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "I3GNXOO4",
    "block": "[[[10,0],[14,0,\"item-content barrel-count-field\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label barrel-count-field__label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],null,[[\"key\"],[[33,1]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"item-input barrel-count-field__input\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"min\",\"value\"],[0,[33,3]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\",\"labelKey\",\"input-number-spinner\",\"barrelCount\"]]",
    "moduleName": "bottomline-execution/templates/components/barrel-count-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});