define("bottomline-execution/controllers/truck-settings", ["exports", "@ember/object/computed", "@ember/controller", "@ember/service"], function (_exports, _computed, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    online: (0, _computed.alias)('appState.online'),
    actions: {
      return: function _return() {
        this.get('online') && this.transitionToRoute('shifts.index');
      }
    }
  });

  _exports.default = _default;
});