define("bottomline-execution/models/inventory", ["exports", "@ember/object", "@ember/utils", "ember-data", "moment", "bottomline-execution/models/base"], function (_exports, _object, _utils, _emberData, _moment, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _base.default.extend({
    InventoryDT: attr('date'),
    inventoryQty: attr('number'),
    StorageID: attr('string'),
    isOutdated: (0, _object.computed)('InventoryDT', function () {
      var outdateInterval = 240; // 4 hours

      var inventoryDateTimeUTC = (0, _moment.default)(new Date(this.get('InventoryDT')).toUTCString());
      var currentDateTimeUTC = (0, _moment.default)(new Date().toUTCString());
      return inventoryDateTimeUTC.isBefore(currentDateTimeUTC.subtract(outdateInterval, 'minutes'));
    }),
    siteStorage: (0, _object.computed)('StorageID', function () {
      return this.get('store').peekRecord('storage', this.get('StorageID'));
    }),
    // StorageID is required to display it correctly on the unload page
    // it can be undefined because BE back-end API may not return it
    canBeUpdated: (0, _object.computed)('StorageID', function () {
      return (0, _utils.isPresent)(this.get('StorageID'));
    })
  });

  _exports.default = _default;
});