define("bottomline-execution/templates/components/f7-side-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gWKayzS3",
    "block": "[[[10,0],[14,0,\"panel-overlay\"],[12],[13],[1,\"\\n\"],[10,0],[15,0,[29,[\"panel panel-\",[36,0],\" panel-reveal\"]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"content-block\"],[12],[1,\"\\n    \"],[10,2],[12],[46,[33,2],null,[[\"data\"],[[33,3]]],null],[13],[1,\"\\n    \"],[10,2],[12],[10,\"button\"],[15,\"onClick\",[28,[37,4],[[30,0],\"close\",[33,0]],null]],[14,0,\"button button-fill\"],[12],[1,[28,[35,5],null,[[\"key\"],[\"close\"]]]],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"side\",\"component\",\"componentName\",\"data\",\"action\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/f7-side-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});