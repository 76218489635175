define("bottomline-execution/mirage/factories/otc-materials", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    materialId: '2323',
    materialOtcId: '34'
  });

  _exports.default = _default;
});