define("bottomline-execution/components/end-inventory/trip-stop-storage-line", ["exports", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['trip-stop-storage'],
    classNameBindings: ['isSelected'],
    tagName: 'tr',
    activeTripStopStorageId: null,
    isSelected: (0, _object.computed)('activeTripStopStorageId', function () {
      return this.get('activeTripStopStorageId') === this.get('tripStopStorage.id');
    }),
    didInsertElement: function didInsertElement() {
      if ((0, _utils.isPresent)(this.get('tripStopStorage'))) {
        this.get('tripStopStorage').calculateTotalCompartmentQty();
      }
    },
    actions: {
      emptyTripStopTruckStorages: function emptyTripStopTruckStorages(tripStopStorage) {
        tripStopStorage.set('confirmedQty', 0);
        tripStopStorage.get('trip_stop.trip_stop_truck_storages').filter(function (tsts) {
          return tsts.get('MaterialID') === tripStopStorage.get('MaterialID');
        }).setEach('confirmedQty', 0);
      }
    }
  });

  _exports.default = _default;
});