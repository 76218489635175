define("bottomline-execution/mirage/config", ["exports", "bottomline-execution/config/environment", "bottomline-execution/mirage/localizations"], function (_exports, _environment, _localizations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    _environment.default.i18n.endpoint = "".concat(_environment.default.host, "/").concat(_environment.default.api);
    this.urlPrefix = _environment.default.host;
    this.namespace = _environment.default.api;
    this.timing = 0;
    this.passthrough('/truck_positions', ['get', 'post', 'options']);
    this.passthrough('//maps.googleapis.com/maps/api/js');
    this.get('/pictures');
    this.get('/extra_times');
    this.get('/trucks');
    this.get('/trailers');
    this.get('/otc_materials');
    this.get('/adr_materials');
    this.get('/material_sellers');
    this.get('/materials');
    this.get("".concat(_environment.default.host, "/settings.json"), function () {
      return {};
    });
    this.get('/localizations', function () {
      return _localizations.default;
    });
    this.get('/trips');
    this.get('/shifts', function (db) {
      return {
        shifts: db.shifts.all().models.map(function (shift) {
          return Object.assign({}, shift, {
            truck_inventories: db.truckInventories.all().models
          });
        }),
        meta: {
          total_pages: 1,
          shiftEvents: [{
            name: 'start',
            options: {
              from: ['planned', 'paused'],
              to: 'started'
            }
          }, {
            name: 'finish',
            options: {
              from: ['started'],
              to: 'finished'
            }
          }],
          tripEvents: [{
            name: 'start',
            options: {
              from: ['planned', 'paused'],
              to: 'started'
            }
          }, {
            name: 'finish',
            options: {
              from: ['started'],
              to: 'finished'
            }
          }],
          tripStopEvents: [{
            name: 'start',
            options: {
              from: ['planned', 'paused'],
              to: 'started'
            }
          }, {
            name: 'finish',
            options: {
              from: ['started'],
              to: 'finished'
            }
          }]
        }
      };
    });
    this.get('/shifts/:id');
    this.get('/trips/:id');
    this.post('/users/sign_in', function (db, request) {
      var payload = JSON.parse(request.requestBody.split('=').reverse()[0]);
      return {
        user_name: payload.user.user_name,
        user_display_name: payload.user.user_display_name,
        user_email: 'trucker',
        user_token: 'oeleo',
        user_role: 'driver'
      };
    });
    this.post('truck_settings', function (db, request) {
      var settings = JSON.parse(request.requestBody).truck_settings;
      return {
        truck_settings: Object.assign({}, settings, {
          id: Date.now()
        })
      };
    });
    this.post('shifts/:id/update_state', function (db, req) {
      return {
        shift: db.shifts.all().update(req.params.id, {
          state: req.params.state
        })
      };
    });
  } //this.put('/trips/:id', (db, request) => {
  //  let newTripStops = JSON.parse(request.requestBody)['trip'].trip_stop_attributes.reject((e) => { return !(/ember/.test(e.id)) || e.id === null; });
  //  let trip = db.trips.find(request.params.id);
  //  db.trips.update(request.params.id, { state: request.params.state });
  //  newTripStops.forEach((trip_stop_rec) => {
  //    let underscoreTRec = {};
  //    for (let key in trip_stop_rec) {
  //      underscoreTRec[key.underscore()] = trip_stop_rec[key];
  //    }
  //    underscoreTRec['id'] = parseInt(Math.random() * 1000);
  //    underscoreTRec['trip_stop_storages'] = [];
  //    underscoreTRec['trip_stop_truck_storages'] = [];
  //    db['trip-stops'].insert(underscoreTRec);
  //    let newTripStopStorages = trip_stop_rec.trip_stop_storage_attributes.reject((e) => { return !(/ember/.test(e.id)) || e.id === null; });
  //    let newTripStopTruckStorages = trip_stop_rec.trip_stop_truck_storage_attributes.reject((e) => { return !(/ember/.test(e.id)) || e.id === null; });
  //    newTripStopTruckStorages.forEach((rec) => {
  //      let underscoreRec = {};
  //      for (let key in rec) {
  //        underscoreRec[key.underscore()] = rec[key];
  //      }
  //      underscoreRec['id'] = parseInt(Math.random() * 1000);
  //      underscoreRec['trip_stop_id'] = underscoreTRec.id;
  //      db['trip-stop-truck-storages'].insert(underscoreRec);
  //    });
  //    newTripStopStorages.forEach((rec) => {
  //      let underscoreRec = {};
  //      for (let key in rec) {
  //        underscoreRec[key.underscore()] = rec[key];
  //      }
  //      underscoreRec['id'] = parseInt(Math.random() * 1000);
  //      underscoreRec['trip_stop_id'] = underscoreTRec.id;
  //      db['trip-stop-storages'].insert(underscoreRec);
  //    });
  //  });
  //  return {
  //    trips: [trip],
  //    trip_stops: db['trip-stops'],
  //    trip_stop_storages: db['trip-stop-storages'],
  //    trip_stop_truck_storages: db['trip-stop-truck-storages']
  //  };
  //});
  //
  //   this.get('/trucks');
  //
  //   this.post('/materials');
  //   this.post('/truck_positions');
  //
  //   this.get('/questions');
  //   this.post('/pictures');
  //   this.post('/answers');
  // this.get('/contacts/:id', function(db, request) {
  //  var contactId = +request.params.id;
  //  var contact = db.contacts.findAll(contactId);
  //  var addresses = db.addresses
  //    .filterBy('contact_id', contactId);
  //
  //  return {
  //    contact: contact,
  //    addresses: addresses
  //  };
  // });

});