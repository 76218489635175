define("bottomline-execution/mirage/factories/site", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    address: function address() {
      return _emberCliMirage.faker.address.streetAddress();
    },
    city: function city() {
      return _emberCliMirage.faker.address.city();
    },
    ext_site_id: function ext_site_id() {
      return _emberCliMirage.faker.random.uuid();
    },
    ext_debtor_id: function ext_debtor_id() {
      return _emberCliMirage.faker.random.uuid();
    },
    name: function name() {
      return _emberCliMirage.faker.address.state();
    },
    zip_code: function zip_code() {
      return _emberCliMirage.faker.address.zipCode();
    }
  });

  _exports.default = _default;
});