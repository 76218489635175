define("bottomline-execution/initializers/shared", ["exports", "@ember/array", "@ember/object"], function (_exports, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize(container) {
    var sharedStore = _object.default.create({
      languages: (0, _array.A)()
    });

    container.registerOptionsForType('globals', {
      instantiate: false,
      singleton: true
    });
    container.register('globals:shared', sharedStore);
    container.inject('controller', 'shared', 'globals:shared');
    container.inject('service', 'shared', 'globals:shared');
  }

  var _default = {
    name: 'shared',
    initialize: initialize
  };
  _exports.default = _default;
});