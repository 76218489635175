define("bottomline-execution/components/shift-item", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service", "bottomline-execution/mixins/trip-sorter"], function (_exports, _computed, _component, _object, _service, _tripSorter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend(_tripSorter.default, {
    appConfiguration: (0, _service.inject)(),
    tagName: '',
    selectedShift: (0, _computed.alias)('parentController.selectedShift'),
    truckSettings: (0, _computed.alias)('appConfiguration.truckSettings'),
    isSelected: (0, _object.computed)('selectedShift', function () {
      return this.get('selectedShift') === this.get('shift.id');
    }),
    trips: (0, _computed.alias)('shift.trips'),
    areTripsLoaded: (0, _computed.alias)('shift.trips.isFulfilled')
  });

  _exports.default = _default;
});