define("bottomline-execution/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    var truck = server.create('truck', 1, {
      user_id: 1
    });
    var trips = server.createList('trip', 2, {
      state: 'started',
      truck_id: truck.id
    });
    var material = server.create('material', {
      material_name_short: 'Diesel',
      material_name_long: 'Diesel',
      material_density: 0.98
    });
    var truckStorage = server.create('truck-storage', {
      id: 0,
      truck_storage_id: 0
    });
    server.create('truck-inventory', {
      material_id: material.id,
      current_qty: 0,
      truck_storage_id: truckStorage.id,
      truck_id: truck.id
    });
    var delivery = server.create('site', {
      name: 'BLS delivery'
    });
    var load = server.create('site', {
      name: 'BLS load'
    });
    trips.forEach(function (trip) {
      var tripStops = [];
      tripStops = tripStops.concat(server.create('trip-stop', {
        sitename: delivery.name,
        site_id: delivery,
        trip_id: trip.id,
        sequence_nb: 2,
        planned_qty: 100,
        storage_id: delivery.storage_id
      }));
      tripStops = tripStops.concat(server.create('trip-stop', {
        sitename: load.name,
        site_id: load,
        trip_id: trip.id,
        activity_code: 'L',
        sequence_nb: 1,
        planned_qty: 100,
        storage_id: load.storage_id
      }));
      tripStops.forEach(function (tripStop) {
        server.create('trip-stop-storage', {
          planned_qty: 100,
          trip_stop_id: tripStop.id,
          material_id: material.id,
          storage_id: tripStop.storage_id
        });
        server.create('trip-stop-truck-storage', {
          trip_stop_id: tripStop.id,
          material_id: material.id,
          truck_storage_id: truckStorage.id,
          storage_id: tripStop.storage_id
        });
      });
    });
    server.create('shift', {
      trip_ids: trips.map(function (trip) {
        return trip.id;
      })
    });
  } // {id: 108906, Status: "2", SequenceNb: -1, ConfirmedSequenceNb: -1, TripID: 14245, longitude: null,…}
  // 1: {id: 108874, Status: "2", SequenceNb: 0, ConfirmedSequenceNb: 0, TripID: 14245, longitude: 12.59214,…}
  // 2: {id: 108879, Status: "2", SequenceNb: 1, ConfirmedSequenceNb: 1, TripID: 14245, longitude: 13.13693,…}
  // 3: {id: 108882, Status: "2", SequenceNb: 2, ConfirmedSequenceNb: 2, TripID: 14245, longitude: 11.26369,…}
  // 4: {id: 108880, Status: "2", SequenceNb: 3, ConfirmedSequenceNb: 3, TripID: 14245, longitude: 9.42315,…}
  // 5: {id: 108881, Status: "2", SequenceNb: 4, ConfirmedSequenceNb: 4, TripID: 14245, longitude: 10.61867,…}
  // 6: {id: 108878, Status: "2", SequenceNb: 5, ConfirmedSequenceNb: 5, TripID: 14245, longitude: 11.88166,…}
  //

});