define("bottomline-execution/services/hp-scanner", ["exports", "@ember/object/computed", "@ember/service", "rsvp"], function (_exports, _computed, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    toast: (0, _service.inject)(),
    f7: (0, _service.inject)('framework7'),
    lastScanTimestamp: 0,
    originalSSIDPromise: null,
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    betterScanPage: function betterScanPage(resultAction) {
      var _this = this;

      this.get('f7').hidePreloader();
      this.get('f7').showPreloader({
        statusMessage: 'Scanning...'
      }); // Cooldown of 30 seconds after performing a scan. Cooldown is reset when scan ends.
      // This prevents problems from users pressing the scan button multiple times.

      if (this.get('lastScanTimestamp') > Math.floor(Date.now() / 1000) - 30) {
        this.get('toast').error('Already scanning!');
        return;
      }

      this.set('lastScanTimestamp', Math.floor(Date.now() / 1000));

      if (this.get('appConfiguration.truckSettings.use_access_point')) {
        this._scannerStartBrowse().then(function () {
          return _this._scannerList();
        }).then(function (scanners) {
          return _this._scannerSelect(scanners);
        }).then(function () {
          return _this._scannerScan();
        }).then(function (result) {
          return resultAction(result);
        }).catch(function (error) {
          if (error === 'adferror') {
            error = 'No paper in scanner'; // better error reporting
          }

          _this.get('toast').error(error, 'Error');
        }).then(function () {
          return _this._accessPointCleanup();
        }).then(function () {
          return _this._scannerStopBrowse();
        }).catch(function (error) {
          console.log('Unable to stop browsing for scanners:', error);
        }).finally(function () {
          return _this._accessPointCleanup();
        });
      } else {
        this._wifiCurrentSSID().then(function (currentSSID) {
          return _this.set('originalSSID', currentSSID);
        }).then(function () {
          return _this._wifiScan();
        }).then(function (neighbourhoodNetworks) {
          return _this._wifiList(neighbourhoodNetworks);
        }).then(function (configuredNetworks) {
          return _this.set('configuredNetworks', configuredNetworks);
        }).then(function () {
          return _this._wifiAdd();
        }).then(function () {
          return _this._wifiConnectToScanner();
        }).then(function () {
          return _this._scannerStartBrowse();
        }).then(function () {
          return _this._scannerList();
        }).then(function (scanners) {
          return _this._scannerSelect(scanners);
        }).then(function () {
          return _this._scannerScan();
        }).then(function (result) {
          return resultAction(result);
        }).catch(function (error) {
          if (error === 'adferror') {
            error = 'No paper in scanner'; // better error reporting
          }

          _this.get('toast').error(error, 'Error');
        }).then(function () {
          return _this._scannerStopBrowse();
        }).catch(function (error) {
          console.log('Unable to stop browsing for scanners:', error);
        }).finally(function () {
          return _this._wifiCleanup();
        });
      }
    },
    _wifiScan: function _wifiScan() {
      console.log('HP Scanner: _wifiScan()');
      this.get('toast').info('Scanning networks');
      return new _rsvp.default.Promise(function (resolve, reject) {
        return WifiWizard.scan(resolve, reject);
      });
    },
    _wifiList: function _wifiList(neighbourhoodNetworks) {
      console.log('HP Scanner: _wifiList()');
      var ssid = this.get('appState.scanner_ssid');

      if (!ssid) {
        var errorMesage = 'Set scanner SSID or use access point';
        this.get('toast').error(errorMesage);
        return _rsvp.default.Promise.reject(errorMesage);
      }

      var scannerNetwork = neighbourhoodNetworks.find(function (network) {
        return network.SSID === ssid;
      });

      if (!scannerNetwork) {
        this.get('toast').error(ssid, 'Could not find scanner...');
        return _rsvp.default.Promise.reject('Configured network not found');
      }

      return new _rsvp.default.Promise(function (resolve, reject) {
        // listNetworks returns already configured networks on the device
        WifiWizard.listNetworks(resolve, reject);
      });
    },
    _wifiAdd: function _wifiAdd() {
      console.log('HP Scanner: _wifiAdd()');
      var ssid = this.get('appState.scanner_ssid');
      var configuredNetworks = this.get('configuredNetworks');
      var configuredScannerNetwork = configuredNetworks.find(function (network) {
        return network === "\"".concat(ssid, "\"");
      });
      configuredNetworks.push("\"".concat(ssid, "\""));
      this.set('configuredNetworks', configuredNetworks);

      if (!configuredScannerNetwork) {
        var password = this.get('appState.scanner_password');
        return new _rsvp.default.Promise(function (resolve, reject) {
          WifiWizard.addNetwork({
            SSID: "\"".concat(ssid, "\""),
            // Yes, these silly double quotes are necessary:
            auth: {
              algorithm: 'WPA',
              password: "\"".concat(password, "\"")
            } // double quotes are necessary:

          }, resolve, reject);
        });
      }

      return _rsvp.default.Promise.resolve('Network already present');
    },
    _accessPointCleanup: function _accessPointCleanup() {
      console.log('HP Scanner: _accessPointCleanup()');
      this.set('lastScanTimestamp', 0);
      this.get('f7').hidePreloader();
      return new _rsvp.default.Promise(function (resolve) {
        resolve('Scanning message cleared');
      });
    },
    _wifiRemove: function _wifiRemove() {
      console.log('HP Scanner: _wifiRemove()');
      var ssid = this.get('appState.scanner_ssid');
      var configuredNetworks = this.get('configuredNetworks');

      if (!configuredNetworks) {
        return _rsvp.default.Promise.resolve('Network was not added');
      }

      var configuredScannerNetwork = configuredNetworks.find(function (network) {
        return network === "\"".concat(ssid, "\"");
      });

      if (!configuredScannerNetwork) {
        return _rsvp.default.Promise.resolve('Network does not exist');
      }

      return new _rsvp.default.Promise(function (resolve, reject) {
        // Yes, these silly double quotes are still necessary.
        return WifiWizard.removeNetwork("\"".concat(ssid, "\""), resolve, reject);
      });
    },
    _wifiConnectToScanner: function _wifiConnectToScanner() {
      console.log('HP Scanner: _wifiConnectToScanner()');
      this.get('toast').info('Connecting to scanner...');
      var ssid = this.get('appState.scanner_ssid');
      return new _rsvp.default.Promise(function (resolve, reject) {
        return WifiWizard.connectNetwork(ssid, resolve, reject);
      });
    },
    _wifiCurrentSSID: function _wifiCurrentSSID() {
      this.set('originalSSIDPromise', new _rsvp.default.Promise(function (resolve, reject) {
        WifiWizard.getCurrentSSID(resolve, reject);
      }));
      return _rsvp.default.Promise.resolve('All is good');
    },
    _wifiCleanup: function _wifiCleanup() {
      this.get('f7').hidePreloader();
      console.log('HP Scanner: _wifiCleanup()');
      this.set('lastScanTimestamp', 0);

      this._wifiRemove();

      this.get('originalSSIDPromise').then(function (ssid) {
        return new _rsvp.default.Promise(function (resolve, reject) {
          WifiWizard.connectNetwork(ssid, resolve, reject);
        });
      }).catch(function () {
        return _rsvp.default.Promise.resolve('Not originally connected to other network');
      });
    },
    _scannerStartBrowse: function _scannerStartBrowse() {
      return new _rsvp.default.Promise(function (resolve, reject) {
        mobilescan.startBrowsing(null, resolve, reject);
      });
    },
    _scannerStopBrowse: function _scannerStopBrowse() {
      return new _rsvp.default.Promise(function (resolve, reject) {
        mobilescan.stopBrowsing(null, resolve, reject);
      });
    },
    _scannerList: function _scannerList() {
      return new _rsvp.default.Promise(function (resolve, reject) {
        mobilescan.listScanners(null, resolve, reject);
      });
    },
    _scannerSelect: function _scannerSelect(scanners) {
      return new _rsvp.default.Promise(function (resolve, reject) {
        mobilescan.selectScanner({
          ScannerID: scanners[0]
        }, resolve, reject);
      });
    },
    _scannerScan: function _scannerScan() {
      return new _rsvp.default.Promise(function (resolve, reject) {
        mobilescan.scan(null, resolve, reject);
      });
    }
  });

  _exports.default = _default;
});