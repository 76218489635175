define("bottomline-execution/mixins/wait-app-state", ["exports", "@ember/object/mixin", "@ember/service"], function (_exports, _mixin, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _mixin.default.create({
    appConfiguration: (0, _service.inject)(),
    waitForAppState: function waitForAppState() {
      return this.get('appConfiguration.findState');
    }
  });

  _exports.default = _default;
});