define("bottomline-execution/templates/components/edit-language", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FZRtP0Zf",
    "block": "[[[41,[33,1],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n        \"],[1,[33,2,[\"id\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n            \"],[1,[28,[35,3],null,[[\"value\",\"placeholder\"],[[33,4],\"Type description\"]]]],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"buttons-row\"],[12],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[24,0,\"button item-link\"],[4,[38,5],[[30,0],\"editMode\"],null],[12],[1,\"Cancel\"],[13],[1,\"\\n        \"],[11,3],[24,6,\"#\"],[24,0,\"button button-fill color-green\"],[4,[38,5],[[30,0],\"saveLocale\"],null],[12],[1,\"Save\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"td\"],[12],[1,[33,2,[\"id\"]]],[13],[1,\"\\n    \"],[10,\"td\"],[12],[1,[33,2,[\"title\"]]],[13],[1,\"\\n    \"],[10,\"td\"],[12],[11,3],[24,6,\"#\"],[24,0,\"list-button item-link\"],[4,[38,5],[[30,0],\"editMode\"],null],[12],[1,\"Edit\"],[13],[13],[1,\"\\n\"]],[]]],[10,\"td\"],[12],[11,3],[24,6,\"#\"],[24,0,\"button button-fill color-red\"],[4,[38,5],[[30,0],\"deleteLocale\"],null],[12],[1,\"Delete\"],[13],[13],[1,\"\\n\"]],[],false,[\"if\",\"isEditing\",\"language\",\"input\",\"value\",\"action\"]]",
    "moduleName": "bottomline-execution/templates/components/edit-language.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});