define("bottomline-execution/models/base", ["exports", "@ember/object/computed", "@ember/service", "ember-data", "bottomline-execution/utils/guid-util", "@ember/object", "bottomline-execution/mixins/timestamp"], function (_exports, _computed, _service, _emberData, _guidUtil, _object, _timestamp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend(_timestamp.default, {
    appConfiguration: (0, _service.inject)(),
    appState: (0, _computed.alias)('appConfiguration.state'),
    isVolatileRemoveableRecord: (0, _object.computed)('isLocalRecord', 'id', function () {
      return this.get('isLocalRecord') || this.get('id') === null;
    }),
    isLocalRecord: (0, _object.computed)('id', function () {
      return _guidUtil.guidRegex.test(this.get('id'));
    })
  });

  _exports.default = _default;
});