define("bottomline-execution/models/storage", ["exports", "@ember/object/computed", "@ember/object", "@ember/utils", "ember-data", "bottomline-execution/utils/math-util"], function (_exports, _computed, _object, _utils, _emberData, _mathUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Model.extend({
    selected: attr('boolean', {
      defaultValue: false
    }),
    StorageGroupID: attr('string'),
    SiteID: attr('string'),
    MaterialOwnerCode: attr('string'),
    maxStorageQty: attr('number'),
    MaterialID: attr('string'),
    SequenceNb: attr('number'),
    InventoryMethod: attr('number'),
    usableStorageQty: attr('number', {
      defaultValue: 0
    }),
    totalStorageQty: attr('number', {
      defaultValue: 0
    }),
    latitude: attr('number'),
    longitude: attr('number'),
    averageSalesQty: attr('number'),
    RunOutDT: attr('date'),
    RealRunOutDT: attr('date'),
    site: belongsTo('site'),
    recent_inventory: belongsTo('inventory', {
      async: false
    }),
    recent_storage_material: belongsTo('storage-material'),
    ATGDataAvailable: (0, _computed.alias)('recent_inventory.inventoryQty'),
    lastTimeRetrieved: 0,
    isStale: function isStale(referenceTime) {
      var fiveMinutes = 300; // seconds

      var time = this._getCurrentTimeStamp(referenceTime);

      var lastRequest = this.get('lastTimeRetrieved');
      return time > lastRequest + fiveMinutes;
    },
    updateInventory: function updateInventory(inventory, referenceTime) {
      this.setProperties({
        'recent_inventory': inventory,
        'lastTimeRetrieved': this._getCurrentTimeStamp(referenceTime)
      });
    },
    _getCurrentTimeStamp: function _getCurrentTimeStamp(reference) {
      return Math.round(new Date(reference).getTime() / 1000);
    },
    material: (0, _object.computed)('MaterialID', 'isLoaded', function () {
      if (this.get('MaterialID')) {
        return this.store.peekRecord('material', this.get('MaterialID'));
      }
    }),
    materialOrRecentReplaceableMaterial: (0, _object.computed)('material', function () {
      var material = this.get('material');

      if (!material.get('ReplaceableYN')) {
        return material;
      }

      var recentStorageMaterial = this.get('recent_storage_material');

      if ((0, _utils.isEmpty)(recentStorageMaterial)) {
        return material;
      }

      var recentReplaceableMaterial = recentStorageMaterial.get('material');

      if (!(0, _utils.isEmpty)(recentReplaceableMaterial)) {
        return recentReplaceableMaterial;
      } else {
        return material;
      }
    }),
    materialSeller: (0, _object.computed)('MaterialOwnerCode', function () {
      return this.store.peekRecord('material-seller', this.get('MaterialOwnerCode'));
    }),
    availableQty: (0, _object.computed)('recent_inventory.inventoryQty', 'usableStorageQty', function () {
      if (!this.get('recent_inventory')) return null;
      return (0, _mathUtil.round)(Number(this.get('usableStorageQty')) - Number(this.get('recent_inventory').get('inventoryQty')) || 0, 3);
    }),
    currentQty: (0, _object.computed)('recent_inventory.inventoryQty', function () {
      if (!this.get('recent_inventory')) {
        return 0;
      }

      return (0, _mathUtil.round)(Number(this.get('recent_inventory.inventoryQty')), 3);
    })
  });

  _exports.default = _default;
});