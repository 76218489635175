define("bottomline-execution/controllers/trips/show", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    trips: (0, _controller.inject)(),
    points: (0, _object.computed)('model', function () {
      return this.get('model').get('trip_stops').reject(function (tripStop) {
        return tripStop.get('latitude') === null;
      });
    }),
    actions: {
      select: function select(tripStop) {
        this.get('model').get('trip_stops').setEach('selected', false);
        tripStop.set('selected', true);
        return false;
      }
    }
  });

  _exports.default = _default;
});