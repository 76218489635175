define("bottomline-execution/templates/components/terminal-summary", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xFctOHZn",
    "block": "[[[10,0],[14,0,\"card-header terminal\"],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[10,0],[12],[1,[33,0,[\"Name\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"terminal small\"],[12],[1,\"(\"],[1,[33,0,[\"id\"]]],[1,\")\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"card-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card-content-inner\"],[12],[1,\"\\n    \"],[10,\"table\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[1,[28,[35,1],null,[[\"name\",\"size\"],[\"domain\",\"lg\"]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[1,[33,0,[\"Address\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[1,[33,0,[\"ZipCode\"]]],[1,\" \"],[1,[33,0,[\"City\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[10,3],[15,6,[29,[\"tel:\",[33,0,[\"telephoneNumbered\"]]]]],[12],[1,\"\\n            \"],[1,[28,[35,1],null,[[\"name\",\"size\"],[\"phone\",\"lg\"]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[1,[28,[35,2],null,[[\"key\"],[\"column.Telephone\"]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"td\"],[14,0,\"address-details__td\"],[12],[1,\"\\n          \"],[10,3],[15,6,[29,[\"tel:\",[33,0,[\"telephoneNumbered\"]]]]],[12],[1,[33,0,[\"Telephone\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"terminal\",\"material-design-icon\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/terminal-summary.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});