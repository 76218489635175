define("bottomline-execution/templates/components/location-confirmer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/3Jg6EAr",
    "block": "[[[6,[39,0],null,[[\"to\"],[\"location-confirmer\"]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"location-confirmer-container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"location-confirmer\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"location-confirmer__map-container\"],[14,1,\"leafletmap\"],[12],[13],[1,\"\\n      \"],[10,2],[14,0,\"buttons-row\"],[12],[1,\"\\n        \"],[11,3],[24,0,\"button button-fill button-big\"],[4,[38,1],[[30,0],\"cancel\"],null],[12],[1,[28,[35,2],[\"cancel\"],null]],[13],[1,\"\\n        \"],[11,3],[24,0,\"button button-fill button-big color-green\"],[4,[38,1],[[30,0],\"confirm\"],null],[12],[1,[28,[35,2],[\"confirm\"],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"ember-wormhole\",\"action\",\"t\"]]",
    "moduleName": "bottomline-execution/templates/components/location-confirmer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});