define("bottomline-execution/templates/components/f7-text-area-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XFoRAQHw",
    "block": "[[[10,\"li\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-content\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"item-inner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"item-title label\"],[12],[1,[28,[35,0],null,[[\"key\"],[[33,1]]]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"item-input\"],[12],[1,\"\\n      \"],[1,[28,[35,2],null,[[\"value\",\"class\"],[[33,3],[52,[33,5],\"has-error\"]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"editable-t\",\"label\",\"textarea\",\"value\",\"if\",\"hasError\"]]",
    "moduleName": "bottomline-execution/templates/components/f7-text-area-field.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});