define("bottomline-execution/templates/components/bl-ocr-validator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QXzCcHdQ",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,1],[14,0,\"color-green\"],[12],[1,[34,1]],[10,\"i\"],[14,0,\"mdi mdi-check\"],[12],[13],[13],[1,\"\\n\"]],[]],[[[41,[33,2],[[[1,\"    \"],[10,\"button\"],[14,0,\"button button-fill color-green bl-scan-validation-button\"],[15,\"onClick\",[28,[37,3],[[30,0],\"accept\"],null]],[12],[1,[28,[35,4],null,[[\"key\"],[\"accept\"]]]],[13],[1,\"\\n    \"],[10,\"button\"],[14,0,\"button button-fill color-black bl-scan-validation-button\"],[15,\"onClick\",[28,[37,3],[[30,0],\"overrule\"],null]],[12],[1,[28,[35,4],null,[[\"key\"],[\"overrule\"]]]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"button\"],[14,0,\"button button-fill color-black bl-scan-validation-button\"],[15,\"onClick\",[28,[37,3],[[30,0],\"overrule\"],null]],[12],[1,[28,[35,4],null,[[\"key\"],[\"overrule\"]]]],[13],[1,\"\\n\"]],[]]]],[]]]],[],false,[\"if\",\"value\",\"scannerValue\",\"action\",\"editable-t\"]]",
    "moduleName": "bottomline-execution/templates/components/bl-ocr-validator.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});