define("bottomline-execution/mirage/fixtures/contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    id: 1,
    name: 'Zelda'
  }, {
    id: 2,
    name: 'Link'
  }];
  _exports.default = _default;
});