define("bottomline-execution/mirage/factories/trip-stop-storage", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.default.Factory.extend({
    confirmed_qty: null,
    material_id: 1,
    planned_qty: 0,
    trip_id: null,
    trip_stop_id: null
  });

  _exports.default = _default;
});