define("bottomline-execution/services/localization", ["exports", "@ember/service", "@ember/utils", "ember-local-storage", "bottomline-execution/localizations", "bottomline-execution/utils/objects-deep-merge-util"], function (_exports, _service, _utils, _emberLocalStorage, _localizations, _objectsDeepMergeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    localizationAjax: (0, _service.inject)(),
    localizationsStorage: (0, _emberLocalStorage.storageFor)('localizations'),
    i18n: (0, _service.inject)(),
    load: function load() {
      var _this = this;

      var localizationsStorage = this.get('localizationsStorage');
      return this.get('localizationAjax').request('/localizations', {
        data: {
          namespace: 'deliveryplus'
        }
      }).then(function (localizationsDataFromService) {
        localizationsStorage.set('localizations', localizationsDataFromService);

        _this._setLocalizations((0, _objectsDeepMergeUtil.default)(_localizations.default, localizationsDataFromService));
      }).catch(function () {
        var localizationsDataFromStorage = localizationsStorage.get('localizations');

        if ((0, _utils.isEmpty)(localizationsDataFromStorage)) {
          _this._setLocalizations(_localizations.default);
        } else {
          _this._setLocalizations((0, _objectsDeepMergeUtil.default)(_localizations.default, localizationsDataFromStorage));
        }
      });
    },
    _setLocalizations: function _setLocalizations(localizationsData) {
      var _this2 = this;

      Object.keys(localizationsData).forEach(function (locale) {
        _this2.get('shared.languages').pushObject({
          id: locale,
          title: localizationsData[locale].description_language || locale
        });

        _this2.get('i18n').addTranslations(locale, localizationsData[locale]);
      });
    }
  });

  _exports.default = _default;
});